<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7">
                <div class=" text-h5">Mayores</div>
            </div>
            <!-- FECHA FINAL -->
            <div class="col-12 col-md-5">
                <q-select v-model="selectAño" filled label="Seleccione el año" class="q-mr-sm" name="event" outlined
                    dense :options="itemsAños">
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <!-- <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy> -->
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetEstadoDeResultados">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportarExcelDos">
                            <q-tooltip>
                                Generar Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-pdf-box" @click="ExportarPdf">
                            <q-tooltip>
                                Generar PDF
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>
        </div>
        <q-table :columns="columns" row-key="_id" :data="itemsMayores" :rows-per-page-options="[0]"
            :pagination.sync="pagination">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="numero" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ props.row.numero }}</b>
                        <span v-else>{{ props.row.numero }}</span>
                    </q-td>
                    <q-td key="descripcion" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ props.row.descripcion }}</b>
                        <span v-else>{{ props.row.descripcion }}</span>
                    </q-td>
                    <q-td key="saldoInicial" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{
                    formatCurrency(props.row.saldoInicial) }}</b>
                        <span v-else>{{ formatCurrency(props.row.saldoInicial) }}</span>
                    </q-td>
                    <q-td key="cargos" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ formatCurrency(props.row.cargos)
                            }}</b>
                        <span v-else>{{ formatCurrency(props.row.cargos) }}</span>
                    </q-td>
                    <q-td key="abonos" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ formatCurrency(props.row.abonos)
                            }}</b>
                        <span v-else>{{ formatCurrency(props.row.abonos) }}</span>
                    </q-td>
                    <q-td key="saldoFinal" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ formatCurrency(props.row.saldoFinal)
                            }}</b>
                        <span v-else>{{ formatCurrency(props.row.saldoFinal) }}</span>
                    </q-td>
                    <q-td key="acumuladosCargos" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{
                    formatCurrency(props.row.acumuladosCargos) }}</b>
                        <span v-else>{{ formatCurrency(props.row.acumuladosCargos) }}</span>
                    </q-td>
                    <q-td key="acumuladosAbonos" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{
                    formatCurrency(props.row.acumuladosAbonos) }}</b>
                        <span v-else>{{ formatCurrency(props.row.acumuladosAbonos) }}</span>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import { EstadoResultadosPDF } from '../../PDF/EstadoResultadosPDF.js'
import * as XLSX from 'xlsx';
import { MayoresPdf } from '../../PDF/MayoresPdf.js'

export default {
    components: {},
    data() {
        return {
            selectAño: "2025",
            itemsAños: ["2025", "2024", "2023", "2022", "2021", "2020", "2019"],
            pagination: {
                rowsPerPage: 15
            },
            columns: [
                { name: 'numero', align: 'center', label: 'Año', field: 'numero', style: 'text-align: left;' },
                { name: 'descripcion', align: 'center', label: 'Mes', field: 'descripcion', style: 'text-align: left; max-width: 100px' },
                { name: 'saldoInicial', align: 'center', label: 'Saldo inicial', field: 'saldoInicial', style: 'text-align: right;' },
                { name: 'cargos', align: 'center', label: 'Cargos', field: 'cargos', style: 'text-align: right;' },
                { name: 'abonos', align: 'center', label: 'Abonos', field: 'abonos', style: 'text-align: right;' },
                { name: 'saldoFinal', align: 'center', label: 'Saldo final', field: 'saldoFinal', style: 'text-align: right;' },

                { name: 'acumuladosCargos', align: 'center', label: 'Acumulados cargos', field: 'acumuladosCargos', style: 'text-align: right;' },
                { name: 'acumuladosAbonos', align: 'center', label: 'Acumulados abonos', field: 'acumuladosAbonos', style: 'text-align: right;' },
            ],
            itemsMayores: [],
            itemsMayoresPdf: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

    },
    created() {
    },
    methods: {

        formatCurrency(value) {
            if (value != '-') {
                // let val = (value/1).toFixed(2)
                // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                return '';
            }
        },

        async GetEstadoDeResultados() {
            this.itemsMayores = [];
            this.itemsMayoresPdf = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando balanza...', messageColor: 'white' })
            try {
                this.itemsMayores = [];
                let response = await axios.get('Reportes/GetReporteMayorAsync/erp_' + this.token.rfc + '/' + this.selectAño);
                this.itemsMayoresPdf = [...response.data]
                for (let a of response.data) {
                    let objLinea = {
                        signo: "=",
                        numero: a.numeroCuenta,
                        descripcion: a.nombrecuenta,
                        saldoInicial: "-",
                        cargos: "-",
                        abonos: "-",
                        saldoFinal: "-",
                        acumuladosCargos: "-",
                        acumuladosAbonos: "-",
                    };
                    this.itemsMayores.push(objLinea);
                    for (let b of a.detalles) {
                        let objDet = {
                            signo: "",
                            numero: b.año,
                            descripcion: b.mes,
                            saldoInicial: b.saldoInicial,
                            cargos: b.cargos,
                            abonos: b.abonos,
                            saldoFinal: b.saldoFinal,
                            acumuladosCargos: b.acumuladosCargos,
                            acumuladosAbonos: b.acumuladosAbonos,
                        };
                        this.itemsMayores.push(objDet);
                    }
                    let objEspacio = {
                        signo: "",
                        numero: "",
                        descripcion: "",
                        saldoInicial: "-",
                        cargos: "-",
                        abonos: "-",
                        saldoFinal: "-",
                        acumuladosCargos: "-",
                        acumuladosAbonos: "-",
                    };
                    this.itemsMayores.push(objEspacio);
                }

                // console.log(response.data)
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportarPdf() {
            if (this.itemsMayores.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero el reporte',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await MayoresPdf(this.itemsMayoresPdf, this.empresa, this.empresa.color, this.selectAño);
            const fileName = 'REPORTE DE MAYORES ' + this.token.empresa + ' PERIODO ' + this.selectAño;
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        async ExportarExcelDos() {
            if (this.itemsMayores.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero el reporte',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            let pre = [...this.itemsMayores];
            pre.forEach(e => {
                if (e.numero === "") {
                    e.descripcion = "";
                    e.saldoInicial = "";
                    e.cargos = "";
                    e.abonos = "";
                    e.saldoFinal = "";
                    e.acumuladosCargos = "";
                    e.acumuladosAbonos = "";
                }
            });
            const data = [...pre];
            const columnsToInclude = ['numero', 'descripcion', 'saldoInicial', 'cargos', 'abonos', 'saldoFinal', 'acumuladosCargos', 'acumuladosAbonos'];
            const datos = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const arrayDeValores = datos.map(objeto => Object.values(objeto));
            const libroTrabajo = XLSX.utils.book_new();
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['REPORTE DE MAYORES'],
                [this.token.empresa],
                ['PERIODO ' + this.selectAño],
                [],
                [
                    'Año',
                    'Mes',
                    'Saldo inicial',
                    'Cargos',
                    'Abonos',
                    'Saldo final',
                    'Acumulados cargos',
                    'Acumulados abonos',
                ],
                ...arrayDeValores.map((registro, index) => [
                    registro[0],
                    registro[1],
                    { t: 'n', v: registro[2], z: '#,##0.00' },
                    { t: 'n', v: registro[3], z: '#,##0.00' },
                    { t: 'n', v: registro[4], z: '#,##0.00' },
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                    { t: 'n', v: registro[6], z: '#,##0.00' },
                    { t: 'n', v: registro[7], z: '#,##0.00' },
                ]),
            ]);
            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 7, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 7, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 7, r: 2 } });

            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');
            const nombreArchivo = 'REPORTE DE MAYORES ' + this.token.empresa + ' PERIODO ' + this.selectAño;
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },
    }
}
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}
</style>