<template>
    <div class="q-pa-md">
        <!-- DIALOG PARA VER LOS PDF -->
        <q-dialog v-model="dialogPDF" transition-show="scale" transition-hide="scale" maximized>
            <visor-pdf ref="miComponenteHijo" @CloseDialogPdf="dialogPDF = false"></visor-pdf>
        </q-dialog>
        <!-- DIALOG PARA VER LAS POLIZAS -->
        <q-dialog v-model="dialogListPolizas" transition-show="scale" transition-hide="scale" maximized>
            <comprobantes-polizas @cierraVentana="dialogListPolizas = false"></comprobantes-polizas>
        </q-dialog>
        <!-- MENU -->
        <div class="row no-wrap items-center q-mt-md q-pa-sm">
            <q-space />
            <q-select outlined dense v-model="selectedAnio" :options="itemsAnios" label="Año" style="width:80px"
                class="q-mr-xs" />
            <q-select outlined dense v-model="selectedMes" :options="itemsMes" label="Mes" style="width:170px"
                class="q-mr-xs" />
            <q-btn push color="amber-9" @click="GetReporte" icon="mdi-text-box-search-outline" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-space />
        </div>
        <!-- INGRESO -->
        <q-table title="Comprobantes facturados (Ingreso)" :pagination.sync="pagination1" :data="itemsIngreso"
            :columns="columns" row-key="mes" :rows-per-page-options="[0]">
            <template v-slot:top-right>
                <q-btn color="primary" icon-right="archive" label="Conciliar" @click="conciliarContabilizadosI()" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="Acciones" auto-width>
                        <q-btn keysize="sm" color="red-10" round dense @click="VerComprobante(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip>
                                Ver pdf
                            </q-tooltip>
                        </q-btn>
                        <template v-if="props.row.polizas.length != 0">
                            <q-btn keysize="sm" color="green" round dense @click="VerListPolizas(props.row)"
                                icon="mdi-check-circle-outline">
                                <q-tooltip>
                                    Ver pólizas
                                </q-tooltip>
                            </q-btn>
                        </template>
                        <template v-else>
                            <q-btn keysize="sm" color="red-10" round dense @click="ConciliaSat(props.row)"
                                icon="mdi-alert-circle">
                                <q-tooltip>
                                    Sin contabilizar
                                </q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                    <q-td key="fecha" :props="props">{{ props.row.fecha }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="subTotal" :props="props">{{ FormatoMiles(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatoMiles(props.row.total) }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                </q-tr>
            </template>
        </q-table>
        <!-- EGRESO -->
        <q-table title="Comprobantes facturados (Egreso)" :pagination.sync="pagination2" :data="itemsEgreso"
            :columns="columns" row-key="mes" :rows-per-page-options="[0]">
            <template v-slot:top-right>
                <q-btn color="primary" icon-right="archive" label="Conciliar" @click="conciliarContabilizadosE()" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="Acciones" auto-width>
                        <q-btn keysize="sm" color="red-10" round dense @click="VerComprobante(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip>
                                Ver pdf
                            </q-tooltip>
                        </q-btn>
                        <template v-if="props.row.polizas.length != 0">
                            <q-btn keysize="sm" color="green" round dense @click="VerListPolizas(props.row)"
                                icon="mdi-check-circle-outline">
                                <q-tooltip>
                                    Ver pólizas
                                </q-tooltip>
                            </q-btn>
                        </template>
                        <template v-else>
                            <q-btn keysize="sm" color="red-10" round dense @click="ConciliaSat(props.row)"
                                icon="mdi-alert-circle">
                                <q-tooltip>
                                    Sin contabilizar
                                </q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                    <q-td key="fecha" :props="props">{{ props.row.fecha }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="subTotal" :props="props">{{ FormatoMiles(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatoMiles(props.row.total) }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                </q-tr>
            </template>
        </q-table>
        <!-- COMPLEMENTOS DE PAGO -->
        <q-table title="Comprobantes facturados (Complementos de Pago)" :pagination.sync="pagination3" :data="itemsPago"
            :columns="columns" row-key="mes" :rows-per-page-options="[0]">
            <template v-slot:top-right>
                <q-btn color="primary" icon-right="archive" label="Conciliar" @click="conciliarContabilizadosCP()" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="Acciones" auto-width>
                        <q-btn keysize="sm" color="red-10" round dense @click="VerComprobante(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip>
                                Ver pdf
                            </q-tooltip>
                        </q-btn>
                        <template v-if="props.row.polizas.length != 0">
                            <q-btn keysize="sm" color="green" round dense @click="VerListPolizas(props.row)"
                                icon="mdi-check-circle-outline">
                                <q-tooltip>
                                    Ver pólizas
                                </q-tooltip>
                            </q-btn>
                        </template>
                        <template v-else>
                            <q-btn keysize="sm" color="red-10" round dense @click="ConciliaSat(props.row)"
                                icon="mdi-alert-circle">
                                <q-tooltip>
                                    Sin contabilizar
                                </q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                    <q-td key="fecha" :props="props">{{ props.row.fecha }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="subTotal" :props="props">{{ FormatoMiles(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatoMiles(props.row.total) }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                </q-tr>
            </template>
        </q-table>
        <!-- EGRESO -->
        <q-table title="Comprobantes facturados (Nómina)" :pagination.sync="pagination4" :data="itemsNomina"
            :columns="columns" row-key="mes" :rows-per-page-options="[0]">
            <template v-slot:top-right>
                <q-btn color="primary" icon-right="archive" label="Conciliar" @click="conciliarContabilizadosN()" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="Acciones" auto-width>
                        <q-btn keysize="sm" color="red-10" round dense @click="VerComprobante(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip>
                                Ver pdf
                            </q-tooltip>
                        </q-btn>
                        <template v-if="props.row.polizas.length != 0">
                            <q-btn keysize="sm" color="green" round dense @click="VerListPolizas(props.row)"
                                icon="mdi-check-circle-outline">
                                <q-tooltip>
                                    Ver pólizas
                                </q-tooltip>
                            </q-btn>
                        </template>
                        <template v-else>
                            <q-btn keysize="sm" color="red-10" round dense @click="ConciliaSat(props.row)"
                                icon="mdi-alert-circle">
                                <q-tooltip>
                                    Sin contabilizar
                                </q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                    <q-td key="fecha" :props="props">{{ props.row.fecha }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="subTotal" :props="props">{{ FormatoMiles(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatoMiles(props.row.total) }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script>
import axios from 'axios'
import { parseISO, parse, isSameMonth } from 'date-fns'
import * as XLSX from 'xlsx';
import ChartComponent from "../Graficas/ChartComponent.vue";
import { QSpinnerCube } from 'quasar'
import visorPdf from '../PDF/VisorPDF.vue';
import ComprobantesPolizas from './ComprobantesPolizas.vue'
// import Utils from "../Graficas/Utils.js";

export default {
    components: {
        ChartComponent,
        visorPdf,
        ComprobantesPolizas
    },
    data() {
        return {
            itemsAnios: ['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: 2025,
            selectedMes: null,

            pagination1: {
                rowsPerPage: 10
            },
            pagination2: {
                rowsPerPage: 10
            },
            pagination3: {
                rowsPerPage: 10
            },
            pagination4: {
                rowsPerPage: 10
            },

            itemsIngreso: [],
            itemsEgreso: [],
            itemsPago: [],
            itemsNomina: [],

            columns: [
                { name: 'Acciones', align: 'center', label: 'Acciones', field: 'Acciones', sortable: true },
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'subTotal', align: 'right', label: 'Sub Total', field: 'subTotal', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                { name: 'folioFiscal', align: 'left', label: 'Folio fiscal', field: 'folioFiscal', sortable: true },
                // { name: 'mes', align: 'center', label: 'Mes', field: 'mes', sortable: true },
            ],
            dialogPDF: false,
            dialogListPolizas: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        rutaAxios() {
            return this.$store.state.rutaMongoStore
        },

        rutaDescargas() {
            return this.$store.state.rutaDescargasStore
        },
    },
    created() {
        this.GetMesActual();
    },
    methods: {
        GetMesActual() {
            var fechaActual = new Date();
            var mes = fechaActual.getMonth();
            this.selectedMes = this.itemsMes.find(f => f.value == mes + 1);
        },

        async GetReporte() {
            this.itemsIngreso = [];
            this.itemsEgreso = [];
            this.itemsPago = [];
            this.itemsNomina = [];
            await this.GetIngreso();
            await this.GetEgreso();
            await this.GetPagos();
            await this.GetNomina();
            this.$q.loading.hide()
        },

        async GetIngreso() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Ingresos...', messageColor: 'white' })
            try {
                const fechaI = this.selectedAnio + '-' + this.selectedMes.value + "-01";
                const ultimoDia = await this.UltimoDiaDelMes(this.selectedAnio, this.selectedMes.value);
                const fechaF = this.selectedAnio + '-' + this.selectedMes.value + "-" + ultimoDia;

                let response = await axios.get('ComprobantesContabilizados/GetComprobanteRecibidosCAsync/erp_' + this.token.rfc + '/I/' + fechaI + '/' + fechaF);
                let x = response.data;
                this.itemsIngreso = [...x];
            } catch (error) {
                console.log(error)
            }
        },

        async GetEgreso() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Notas de Crédito...', messageColor: 'white' })
            try {
                const fechaI = this.selectedAnio + '-' + this.selectedMes.value + "-01";
                const ultimoDia = await this.UltimoDiaDelMes(this.selectedAnio, this.selectedMes.value);
                const fechaF = this.selectedAnio + '-' + this.selectedMes.value + "-" + ultimoDia;

                let response = await axios.get('ComprobantesContabilizados/GetComprobanteRecibidosCAsync/erp_' + this.token.rfc + '/E/' + fechaI + '/' + fechaF);
                let x = response.data;
                this.itemsEgreso = [...x];
            } catch (error) {
                console.log(error)
            }
        },

        async GetPagos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Complementos de Pago...', messageColor: 'white' })
            try {
                const fechaI = this.selectedAnio + '-' + this.selectedMes.value + "-01";
                const ultimoDia = await this.UltimoDiaDelMes(this.selectedAnio, this.selectedMes.value);
                const fechaF = this.selectedAnio + '-' + this.selectedMes.value + "-" + ultimoDia;

                let response = await axios.get('ComprobantesContabilizados/GetComprobanteRecibidosCAsync/erp_' + this.token.rfc + '/P/' + fechaI + '/' + fechaF);
                let x = response.data;
                this.itemsPago = [...x];
            } catch (error) {
                console.log(error)
            }
        },

        async GetNomina() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Nóminas...', messageColor: 'white' })
            try {
                const fechaI = this.selectedAnio + '-' + this.selectedMes.value + "-01";
                const ultimoDia = await this.UltimoDiaDelMes(this.selectedAnio, this.selectedMes.value);
                const fechaF = this.selectedAnio + '-' + this.selectedMes.value + "-" + ultimoDia;

                let response = await axios.get('ComprobantesContabilizados/GetComprobanteRecibidosCAsync/erp_' + this.token.rfc + '/N/' + fechaI + '/' + fechaF);
                let x = response.data;
                this.itemsNomina = [...x];
            } catch (error) {
                console.log(error)
            }
        },

        async GetGrafica() {
            const ingresos = this.dataResult.map((item) => item.ingreso);
            const nominas = this.dataResult.map((item) => item.nomina);
            const pagos = this.dataResult.map((item) => item.complementoPago);
            const notas = this.dataResult.map((item) => item.notasCredito);

            const sumaIngresos = this.dataResult.reduce((acumulador, actual) => acumulador + actual.ingreso, 0);
            const sumaNotas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.notasCredito, 0);
            const sumaNominas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.nomina, 0);
            const sumaPagos = this.dataResult.reduce((acumulador, actual) => acumulador + actual.complementoPago, 0);
            const sumaTotal = sumaIngresos + sumaNotas + sumaNominas + sumaPagos;

            let ObjIngresos = {
                label: "Ingresos: " + this.FormatoMiles(sumaIngresos),
                backgroundColor: "rgba(0, 163, 92, 0.5)",
                borderColor: "rgba(0, 163, 92)",
                borderWidth: 1,
                data: ingresos,
            }

            let ObjNomina = {
                label: "Nómina: " + this.FormatoMiles(sumaNominas),
                backgroundColor: "rgba(255, 148, 166, 0.5)",
                borderColor: "rgba(255, 148, 166)",
                borderWidth: 1,
                data: nominas,
            }

            let ObjPagos = {
                label: "Complementos de Pago: " + this.FormatoMiles(sumaPagos),
                backgroundColor: "rgba(255, 108, 55, 0.5)",
                borderColor: "rgba(255, 108, 55)",
                borderWidth: 1,
                data: pagos,
            }

            let ObjNotas = {
                label: "Notas de Crédito: " + this.FormatoMiles(sumaNotas),
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                borderColor: "rgba(54, 162, 235)",
                borderWidth: 1,
                data: notas,
            }

            let chartDatas = {
                labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
                datasets: []
            }
            chartDatas.datasets.push(ObjIngresos)
            chartDatas.datasets.push(ObjNomina)
            chartDatas.datasets.push(ObjPagos)
            chartDatas.datasets.push(ObjNotas)
            this.chartData = { ...chartDatas }

            this.charTitleE = 'Recibidos: ' + this.FormatoMiles(sumaTotal)
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatoMiles(value) {
            return value.toLocaleString('en-US');
        },

        async UltimoDiaDelMes(año, mes) {
            let fecha = new Date(año, mes, 0);
            return fecha.getDate();
        },

        async VerComprobante(item) {
            console.log(item)
            var tipoI = "";
            if (item.tipo === "I") {
                tipoI = "FACTURA";
            } else if (item.tipo === "E") {
                tipoI = "NOTA CREDITO";
            } else if (item.tipo === "P") {
                tipoI = "PAGO";
            } else if (item.tipo === "N") {
                tipoI = "NOMINA";
            }
            try {
                this.dialogPDF = true;
                this.$nextTick(() => {
                    this.$store.state.vistaPreviaStore.tipo = "R"
                    this.$store.state.vistaPreviaStore.tipoComprobanteInterno = tipoI;
                    this.$store.state.vistaPreviaStore.folioFiscal = item.folioFiscal.toUpperCase();
                    this.$store.state.vistaPreviaStore.color = "19775C"
                    this.$store.state.vistaPreviaStore.rfc = this.token.rfc
                    this.$refs.miComponenteHijo.VerComprobante();
                });
            } catch (error) {
                console.log(error)
            }
        },

        async VerListPolizas(item) {
            const polizas = item.polizas;
            this.$store.state.listComprobantesPolizasStore.tipo = "Compras / Gastos";
            this.$store.state.listComprobantesPolizasStore.lista = [];
            this.$store.state.listComprobantesPolizasStore.lista = [...polizas];
            this.dialogListPolizas = true;
        },
        async conciliarContabilizadosI() {
            let foliosFiscales = []
            for (let a of this.itemsIngreso) {
                if (a.polizas.length != 0) {
                } else {
                    foliosFiscales.push(a.folioFiscal);
                }
            }

            console.log(foliosFiscales)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 100, message: 'Conciliando...', messageColor: 'white' })
            try {
                let response = await axios.post('ComprobantesContabilizados/PostConciliaContabilizados/erp_' + this.token.rfc, foliosFiscales);
                console.log(response)
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
            }
        },
        async conciliarContabilizadosE() {
            let foliosFiscales = []
            for (let a of this.itemsEgreso) {
                if (a.polizas.length != 0) {
                } else {
                    foliosFiscales.push(a.folioFiscal);
                }
            }
            console.log(foliosFiscales)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 100, message: 'Conciliando...', messageColor: 'white' })
            try {
                let response = await axios.post('ComprobantesContabilizados/PostConciliaContabilizados/erp_' + this.token.rfc, foliosFiscales);
                console.log(response)
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
            }
        },
        async conciliarContabilizadosCP() {
            let foliosFiscales = []
            for (let a of this.itemsPago) {
                if (a.polizas.length != 0) {
                } else {
                    foliosFiscales.push(a.folioFiscal);
                }
            }
            console.log(foliosFiscales)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 100, message: 'Conciliando...', messageColor: 'white' })
            try {
                let response = await axios.post('ComprobantesContabilizados/PostConciliaContabilizados/erp_' + this.token.rfc, foliosFiscales);
                console.log(response)
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
            }
        },
        async conciliarContabilizadosN() {
            let foliosFiscales = []
            for (let a of this.itemsNomina) {
                if (a.polizas.length != 0) {
                } else {
                    foliosFiscales.push(a.folioFiscal);
                }
            }
            console.log(foliosFiscales)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 100, message: 'Conciliando...', messageColor: 'white' })
            try {
                let response = await axios.post('ComprobantesContabilizados/PostConciliaContabilizados/erp_' + this.token.rfc, foliosFiscales);
                console.log(response)
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
            }
        }
    },
}
</script>