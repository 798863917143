<template>
    <q-page class=" q-pa-lg ">
        <!-- DIALOG QUE MUESTRA LAS POLIZAS QUE AFECTAN PERIODOS DIFERENTES AL CONTABILIZADO -->
        <q-dialog v-model="dialogFueraMes" transition-show="scale" transition-hide="scale" maximized>
            <q-card>
                <q-card-section>
                    <q-toolbar>
                        <template v-if="!banderaSelected">
                            <q-btn dense round color="green-10" icon="mdi-playlist-check" class="q-mr-sm"
                                @click="ConservarTodasLasPolizas()">
                                <q-tooltip>
                                    Conservar todas las pólizas
                                </q-tooltip>
                            </q-btn>
                            <q-btn dense round color="red-10" icon="mdi-playlist-remove" class="q-mr-sm"
                                @click="EliminarTodasLasPolizas()">
                                <q-tooltip>
                                    Eliminar todas las pólizas
                                </q-tooltip>
                            </q-btn>
                        </template>
                        <template v-if="banderaSelected">
                            <q-btn dense round color="green-14" icon="mdi-playlist-check" class="q-mr-sm"
                                @click="ConservarPolizasSeleccionadas()">
                                <q-tooltip>
                                    Conservar pólizas seleccionadas
                                </q-tooltip>
                            </q-btn>
                            <q-btn dense round color="red-7" icon="mdi-playlist-remove" class="q-mr-sm"
                                @click="EliminarPolizasSeleccionadas()">
                                <q-tooltip>
                                    Eliminar pólizas seleccionadas
                                </q-tooltip>
                            </q-btn>
                        </template>
                        <q-toolbar-title>
                            Pólizas que afectan periodos diferentes
                        </q-toolbar-title>
                        <q-btn round color="red" icon="mdi-close" dense @click="dialogConfirmFueraMes = true">
                            <q-tooltip>
                                Cerrar
                            </q-tooltip>
                        </q-btn>
                    </q-toolbar>
                    <q-separator class="full-width" color="primary" inset size="4px" />
                </q-card-section>
                <q-card-section>
                    <q-table :filter="filterFueraMes" selection="multiple" :selected.sync="itemsFueraMesSelected"
                        class="my-sticky-column-table my-sticky-column-table-sec shadow-0 header-tabla"
                        :data="itemsFueraMes" :columns="columns" row-key="_id" :pagination="initialPagination">
                        <template v-slot:body-selection="scope">
                            <q-checkbox :value="scope.itemsFueraMesSelected" />
                        </template>

                        <template v-slot:top>
                            <q-input filled dense debounce="300" class="full-width" v-model="filterFueraMes"
                                placeholder="Filtrar...">
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                        </template>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td>
                                <q-td auto-width key="acciones">
                                    <q-btn size="md" color="red-6" rounded flat dense icon="mdi-file-pdf-box"
                                        @click="verPoliza(props.row)">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">PDF</q-tooltip>
                                    </q-btn>
                                    <q-btn size="md" color="green-10" rounded flat dense icon="mdi-check"
                                        @click="ConservarPolizaF(props.row)">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">Conservar</q-tooltip>
                                    </q-btn>
                                    <q-btn size="md" color="negative" rounded flat dense icon="mdi-delete"
                                        @click="EliminarPolizaF(props.row)">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">Eliminar</q-tooltip>
                                    </q-btn>
                                </q-td>
                                <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                                <q-td key="numPoliza" :props="props">
                                    <q-chip :class="props.row.estatus">
                                        {{ props.row.numPoliza }}
                                        <q-tooltip>
                                            {{ props.row.estatus }}
                                        </q-tooltip>
                                    </q-chip>
                                </q-td>
                                <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                                <q-td key="cargo" :props="props">{{ formatCurrency(props.row.cargo) }}</q-td>
                                <q-td key="abono" :props="props">{{ formatCurrency(props.row.abono) }}</q-td>
                                <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia) }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card-section>
            </q-card>
        </q-dialog>
        <!-- DIALOG PARA CONFIRMAR SI SE CIERRA LA VENTANA -->
        <q-dialog v-model="dialogConfirmFueraMes">
            <q-card class="full-width">
                <q-card-section class="bg-purple text-white">
                    <div class="text-h6">¿Cerrar ventana?</div>
                </q-card-section>
                <q-card-section>
                    <div class="text-subtitle2">Al cerrar la siguiente ventana, las pólizas que se muestran se quedarán
                        registradas en el sistema. ¿Desea continuar?</div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat @click="dialogFueraMes = false" v-close-popup>Cerrar ventana</q-btn>
                    <q-btn flat v-close-popup>Regresar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA VER LOS PDF -->
        <q-dialog v-model="dialogPDF" transition-show="scale" transition-hide="scale" maximized>
            <visor-pdf ref="miComponenteHijo" @CloseDialogPdf="CloseDialogPdf"></visor-pdf>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-lg">Estas seguro de {{ this.accion }} la Póliza.?</span>
                </q-card-section>

                <!-- <q-card-actions align="right"> -->
                <q-card-actions>
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-space></q-space>
                    <q-btn flat label="Aceptar" color="primary" @click="PutEstadoPoliza()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA GENERAR POLIZAS DESDE XML -->
        <q-dialog v-model="dialogGenerarPolizas" persistent transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <q-toolbar>
                        <q-toolbar-title>
                            Generar Pólizas
                        </q-toolbar-title>
                        <q-btn dense round color="green" icon="mdi-cube-send" class="q-mr-sm" @click="PostPolizas">
                            <q-tooltip>
                                Generar Pólizas
                            </q-tooltip>
                        </q-btn>
                        <q-btn round color="red" icon="mdi-close" dense v-close-popup>
                            <q-tooltip>
                                Cerrar
                            </q-tooltip>
                        </q-btn>
                    </q-toolbar>
                    <q-separator class="full-width" color="primary" inset size="4px" />
                </q-card-section>
                <q-card-section>
                    <!-- <q-date v-model="fechaGenerar" class="full-width" landscape /> -->
                    <div class="row">
                        <div class="col">
                            <q-select outlined dense v-model="selectedAño" :options="itemsAños" label="Año"
                                class="q-mr-xs" />
                        </div>
                        <div class="col">
                            <q-select outlined dense v-model="selectedMes" :options="itemsMeses" label="Mes"
                                class="q-mr-xs" option-label="mes" />
                        </div>
                    </div>
                    <q-linear-progress stripe rounded size="20px" :value="progressPolizas" color="warning"
                        class="q-mt-sm" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOG CANCELAR POLIZAS -->
        <q-dialog v-model="dialogCancelarPolizas" persistent transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <q-toolbar>
                        <q-toolbar-title>
                            Cancelar Pólizas
                        </q-toolbar-title>
                        <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm">
                            <q-tooltip>
                                Guardar
                            </q-tooltip>
                        </q-btn>
                        <q-btn round color="red" icon="mdi-close" dense v-close-popup>
                            <q-tooltip>
                                Cerrar
                            </q-tooltip>
                        </q-btn>
                    </q-toolbar>
                    <q-separator class="full-width" color="primary" inset size="4px" />
                </q-card-section>
                <q-card-section>
                    <q-date v-model="fechaCancelar" range class="full-width" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA CREAR UNA NUEVA POLIZA -->
        <q-dialog full-width v-model="dialogNuevaPoliza" persistent transition-show="scale" transition-hide="scale">
            <Poliza @cierraVentana="dialogNuevaPoliza = false"></Poliza>
        </q-dialog>

        <!-- DIALOGO PARA GENERAR UNA NUEVA POLIZA DE CIERRE -->
        <q-dialog v-model="dialogNuevaPolizaCierre" width="800px" transition-show="scale" transition-hide="scale">
            <PolizaCierre @cierraVentanaCierre="dialogNuevaPolizaCierre = false"></PolizaCierre>
        </q-dialog>

        <!-- DIALOGO PARA CREAR LA POLIZA INICIAL -->
        <q-dialog full-width v-model="dialogPolizaInicial" transition-show="scale" transition-hide="scale">
            <poliza-inicial @cierraVentana="dialogPolizaInicial = false"></poliza-inicial>
        </q-dialog>

        <!-- DIALOG PARA FOLIAR LAS POLIZAS -->
        <q-dialog v-model="dialogFoliado" width="800px" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <div class="text-h6">Foliado de pólizas</div>
                    <div class="text-subtitle2">Eset apartado nos permite foliar las pólizas, por si no hay un
                        consecutivo en las mismas.</div>
                </q-card-section>
                <q-card-section class="row items-center">
                    <div class="col-12">
                        <q-select dense bottom-slots filled v-model="selectFoliado" :options="itemsTiposPoliza"
                            label="Seleccione el ipo de Póliza para foliar">
                        </q-select>
                    </div>
                </q-card-section>

                <q-card-actions>
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-space></q-space>
                    <q-btn flat label="Aceptar" color="primary" @click="ConfirmFoliado()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA UNIR LAS POLIZAS -->
        <q-dialog v-model="dialogUnirPolizas" width="800px" transition-show="scale" transition-hide="scale" persistent>
            <q-card>
                <q-card-section>
                    <div class="text-h6">Unir Pólizas</div>
                    <div class="text-subtitle2">Este apartado nos permite unir varias pólizas en una sola</div>
                    <div class="text-subtitle2">En la opción A, nos pemite generar la póliza con los asientos separados.
                    </div>
                    <div class="text-subtitle2">En la opción B, nos pemite generar la póliza con los asientos agrupados
                        por número de cuenta.</div>
                    <div class="text-subtitle2">Una vez guardada la póliza, se eliminaran las polizas anteriores y no se
                        pueden regresar las pólizas a su estado anterior.</div>
                </q-card-section>

                <!-- <q-card-section class="row items-center">
                    <div class="col-12">
                        <q-select dense bottom-slots filled v-model="selectFoliado" :options="itemsTiposPoliza"
                            label="Seleccione el ipo de Póliza para foliar">
                        </q-select>
                    </div>
                </q-card-section> -->

                <q-card-actions>
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-space></q-space>
                    <q-btn flat label="Método A" color="primary" @click="UnirPolizasAsientos()" />
                    <q-btn flat label="Método B" color="primary" @click="UnirPolizasAsientosB()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA FILTRO AVANZADO -->
        <q-dialog v-model="dialogFiltroAvanzado" width="800px" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <div class="text-h6">Filtrar por RFC o nombre</div>
                    <div class="text-subtitle2">Este apartado nos permite buscar pólizas por RFC o por nombre dentro de
                        los XML'S relacionados</div>
                </q-card-section>

                <q-card-section>
                    <div class="q-gutter-xs">
                        <div class="col-12">
                            <q-select dense filled label="Seleccione el cliente / proveedor" v-model="selectRfcNombre"
                                option-label="completo" @filter="filtroRfcNombre" :options="filtroRfcNombres" use-input
                                input-debounce="0" ref="refRfcNombre">
                            </q-select>
                        </div>
                        <!-- <div class="col-12">
                            <q-input dense filled label="Folio fiscal" v-model="selectFolioFiscal"></q-input>
                        </div>
                        <div class="col-12">
                            <q-checkbox keep-color v-model="soloFolioFiscal" label="Sólo folio fiscal" color="teal" />
                        </div> -->
                    </div>
                </q-card-section>

                <q-card-actions vertical>
                    <q-btn color="green-10" flat @click="BusquedaAvanzada()">Buscar por cliente / proveedor</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA CANCELAR POLIZAS DESDE XML -->
        <q-dialog full-width v-model="dialogCancelaXml" persistent transition-show="scale" transition-hide="scale">
            <CancelaDesdeXml @cierraCancelaXml="dialogCancelaXml = false"></CancelaDesdeXml>
        </q-dialog>

        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-3">
                <div class=" text-h5">Lista de Pólizas</div>
            </div>
            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>

                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- TIPOS DE POLIZA -->
            <div class="col-12 col-md-2">
                <q-select dense bottom-slots filled v-model="tipoPoliza" :options="itemsTiposPoliza"
                    label="Tipo de Póliza">
                </q-select>
            </div>
            <div class="col-12 col-md-3 text-right">
                <q-btn flat round color="primary" icon="mdi-magnify" @click="getPolizas()">
                    <q-tooltip>
                        Buscar
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-magnify-minus-outline" @click="GetPolizaNivel()">
                    <q-tooltip>
                        Buscar pólizas a nivel 2
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-book-plus" @click="nuevaPoliza()">
                    <q-tooltip>
                        Nueva Póliza de Diario
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-file-sign" @click="abrirDialogGenerarPolizas()">
                    <q-tooltip>
                        Generar Polizas desde XML's
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-file-cancel" @click="dialogCancelaXml = true">
                    <q-tooltip>
                        Validar comprobantes cancelados
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-calendar-lock-outline" @click="nuevaPolizaCierre">
                    <q-tooltip>
                        Póliza de Cierre
                    </q-tooltip>
                </q-btn>
                <!-- <q-btn flat round color="primary" icon="mdi-book-cancel-outline" @click="abrirCancelarPoliza()">
                    <q-tooltip>
                        Cancelar Polizas
                    </q-tooltip>
                </q-btn> -->
                <q-btn flat round color="primary" icon="mdi-folder-download" @click="descargarLote()">
                    <q-tooltip>
                        Descarga por Lote
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-book-plus-multiple" @click="OpenDialogUnirPolizas()"
                    v-if="banderaUnirPolizas">
                    <q-tooltip>
                        Unir pólizas
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-counter" @click="dialogFoliado = true;">
                    <q-tooltip>
                        Foliado de pólizas
                    </q-tooltip>
                </q-btn>
            </div>
        </div>

        <!-- TABA CON LAS POLIZAS -->
        <q-table :filter="filter" selection="multiple" :selected.sync="selectedPolizas"
            class="my-sticky-column-table my-sticky-column-table-sec shadow-0 header-tabla" :loading="loadingTabla"
            :data="itemPolizas" :columns="columns" row-key="_id" :pagination="initialPagination">

            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body-selection="scope">
                <q-checkbox :value="scope.selectedPolizas" />
            </template>

            <template v-slot:top>
                <q-input filled dense debounce="300" class="full-width" v-model="filter" placeholder="Filtrar...">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                    <template v-slot:after>
                        <q-btn round dense flat icon="mdi-text-search" @click="OpenDialogDiltradoAvanzado()">
                            <q-tooltip>
                                Filtrado avanzado
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">

                    <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td>
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="red-6" rounded flat dense icon="mdi-file-pdf-box"
                            @click="verPoliza(props.row)">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">PDF</q-tooltip>
                        </q-btn>
                        <q-btn v-show="props.row.estatus != 'Validada'" size="md" color="orange" rounded flat dense
                            icon="mdi-delete" @click="confirm(props.row, 'eliminar')">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                        <q-btn v-show="props.row.estatus == 'Activo' || props.row.estatus == 'Validada'" size="md"
                            color="negative" rounded flat dense icon="mdi-book-cancel"
                            @click="confirm(props.row, 'cancelar')">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Cancelar</q-tooltip>
                        </q-btn>
                        <template v-if="banderaEdit">
                            <q-btn v-show="props.row.estatus == 'Activo' || props.row.estatus == 'Validada'" size="md"
                                color="blue" rounded flat dense icon="mdi-pencil" @click="editarPoliza(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </template>
                        <q-btn v-show="props.row.estatus == 'Cancelado'" size="md" color="green" rounded flat dense
                            icon="mdi-checkbox-marked-circle-outline" @click="PutActivarPoliza(props.row)">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Activar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                    <q-td key="numPoliza" :props="props">
                        <q-chip :class="props.row.estatus">
                            {{ props.row.numPoliza }}
                            <q-tooltip>
                                {{ props.row.estatus }}
                            </q-tooltip>
                        </q-chip>
                    </q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="cargo" :props="props">{{ formatCurrency(props.row.cargo) }}</q-td>
                    <q-td key="abono" :props="props">{{ formatCurrency(props.row.abono) }}</q-td>
                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia) }}</q-td>
                </q-tr>
            </template>

            <template v-slot:bottom-row>
                <q-tr>
                    <q-td colspan="100%">
                        <q-chip color="negative" text-color="white" icon="mdi-cancel">
                            Cancelada
                        </q-chip>
                        <q-chip color="blue" text-color="white" icon="mdi-checkbox-marked-circle-outline">
                            Activo
                        </q-chip>
                        <q-chip color="green" text-color="white" icon="mdi-star-check">
                            Validada
                        </q-chip>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>

<script>
import Poliza from './Poliza.vue'
import PolizaCierre from './PolizaCierre.vue'
import PolizaInicial from './PolizaDeApertura'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { PolizaPDF } from '../PDF/PolizaPDF.js'
import JSZip from 'jszip';
import visorPdf from '../PDF/VisorPDF.vue';
import CancelaDesdeXml from './CancelaDesdeXml.vue';

export default {
    components: { Poliza, PolizaCierre, visorPdf, PolizaInicial, CancelaDesdeXml },
    data() {
        return {
            fechaGenerar: new Date(),
            fechaCancelar: { fechaInicial: '', fechaFinal: '' },
            fechaI: new Date(),
            fechaF: new Date(),
            tipoPoliza: 'Todos',
            itemsTiposPoliza: ['Todos', 'Ingreso', 'Egreso', 'Diario'],
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo', field: 'tipo', sortable: true },
                { name: 'numPoliza', align: 'center', label: 'No. Póliza', field: 'numPoliza', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'cargo', align: 'center', label: 'Cargo', field: 'cargo', sortable: true, headerClasses: 'bg-primary text-white', classes: 'bg-grey-2 text-black text-right ellipsis ' },
                { name: 'abono', align: 'center', label: 'Abono', field: 'abono', sortable: true, headerClasses: 'bg-primary text-white', classes: 'bg-grey-2 text-black text-right ellipsis' },
                { name: 'diferencia', align: 'center', label: 'Diferencia', field: 'diferencia', sortable: true, classes: 'text-right' },
            ],
            filter: '',
            loadingTabla: false,
            initialPagination: {
                sortBy: 'tipo',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },

            dialogNuevaPoliza: false,
            dialogGenerarPolizas: false,
            dialogNuevaPolizaCierre: false,
            dialogCancelarPolizas: false,
            dialogConfirm: false,
            dialogPDF: false,

            selectedPolizas: [],
            accion: '',

            //GENERA POLIZAS
            itemsAños: ['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMeses: [
                { mes: 'ENERO', valor: 1 },
                { mes: 'FEBRERO', valor: 2 },
                { mes: 'MARZO', valor: 3 },
                { mes: 'ABRIL', valor: 4 },
                { mes: 'MAYO', valor: 5 },
                { mes: 'JUNIO', valor: 6 },
                { mes: 'JULIO', valor: 7 },
                { mes: 'AGOSTO', valor: 8 },
                { mes: 'SEPTIEMBRE', valor: 9 },
                { mes: 'OCTUBRE', valor: 10 },
                { mes: 'NOVIEMBRE', valor: 11 },
                { mes: 'DICIEMBRE', valor: 12 },
            ],
            selectedAño: null,
            selectedMes: null,
            progressPolizas: 0,

            //POLIZA INICIAL
            dialogPolizaInicial: false,

            banderaEdit: false,

            //PARA FOLIAR LAS POLIZAS
            dialogFoliado: false,
            selectFoliado: 'Todos',

            //PARA UNIR LAS POLIZAS
            dialogUnirPolizas: false,
            banderaUnirPolizas: false,

            //FILTRADO AVANZADO
            dialogFiltroAvanzado: false,
            itemsPolizasRfc: [],
            itemsRfcNombre: [],
            selectRfcNombre: null,
            filtroRfcNombres: null,
            selectFolioFiscal: "",
            soloFolioFiscal: false,

            //CANCELACION DESDE XML
            dialogCancelaXml: false,

            //COMPROBANTES FUERA DEL MES
            dialogFueraMes: false,
            itemsFueraMes: [],
            itemsFueraMesSelected: [],
            filterFueraMes: "",
            dialogConfirmFueraMes: false,
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },

        token() {
            return this.$store.state.usuario;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        itemPolizas() {
            return this.$store.state.listaPolizasStore
        },

        banderaSelected() {
            if (this.itemsFueraMesSelected.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    },

    created() {

    },

    methods: {
        //CREAMOS LAS POLIZAS
        async PostPolizas() {
            if (!this.selectedAño) {
                this.$q.notify({ type: 'warning', message: `Seleccione el año` })
                return;
            }
            if (!this.selectedMes) {
                this.$q.notify({ type: 'info', message: `Seleccione el mes` })
                return;
            }

            const ultimoDia = await this.UltimoDiaDelMes(this.selectedAño, this.selectedMes.valor);
            console.log(ultimoDia);

            this.progressPolizas = 0;
            this.itemsFueraMesSelected = [];
            this.itemsFueraMes = [];
            await this.PostPolizasPublicoEnGeneral(ultimoDia + 1);
            await this.PostPolizasNomina(ultimoDia + 1);
            await this.PostPolizasNominaRecibidas(ultimoDia + 1);
            await this.PostPolizasRecibidosI(ultimoDia + 1);
            await this.PostPolizasEmitidosI(ultimoDia + 1);
            await this.PostPolizasPagosR(ultimoDia + 1);
            await this.PostPolizasPagosE(ultimoDia + 1);
            await this.PostPolizasEmitidosE(ultimoDia + 1);
            await this.PostPolizasRecibidosE(ultimoDia + 1);
            await this.PostPolizaAutoconsumo(ultimoDia + 1);
            console.log(this.itemsFueraMes);
            this.dialogFueraMes = true;
            this.$q.loading.hide()
        },

        async UltimoDiaDelMes(año, mes) {
            let fecha = new Date(año, mes, 0);
            return fecha.getDate();
        },

        async PostPolizasNomina(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de nómina. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasNominaAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasNominaRecibidas(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de nómina recibidas. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasNominaRecibidaAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasRecibidosI(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de gastos. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasRecibidosIAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {
                    console.log(error)
                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasEmitidosI(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de ingresos. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasEmitidosIAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasPagosR(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de pagos a proveedores / acreedores. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasPagosRAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasPagosE(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de pago de clientes.' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasPagosEAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasEmitidosE(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de notas de crédito emitidas. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasEmitidosEAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        async PostPolizasRecibidosE(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de notas de crédito recibidas. ' + a + ' de ' + diaFinal_ + ' días'
                    })
                    let response = await axios.post('AutoConta/PostPolizasRecibidosEAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
                this.progressPolizas += .1
            }
        },

        async PostPolizasPublicoEnGeneral(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    const diaFinal_ = diaFinal - 1
                    this.$q.loading.show({
                        message: 'Generando pólizas de público en general emitidas. ' + a + ' de ' + diaFinal_ + ' días'
                        // message: 'Generando pólizas de público en general emitidas. '
                    })
                    let response = await axios.post('AutoConta/PostPolizasEmitidosPublicoEnGeneralAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {
                    console.log(error)
                }
            }
            this.progressPolizas += .1
        },

        async PostPolizaAutoconsumo(diaFinal) {
            for (let a = 1; a < diaFinal; a++) {
                try {
                    this.$q.loading.show({
                        message: 'Generando pólizas de autoconsumo. ' + a + ' de ' + diaFinal - 1
                    })
                    let response = await axios.post('AutoConta/PostPolizasAutoIAsync/erp_' + this.token.rfc + '/' + this.selectedAño + '/' + this.selectedMes.valor + '/' + a);
                    const x = [...response.data];
                    x.forEach(valor => this.itemsFueraMes.push(valor));
                } catch (error) {

                }
            }
            this.progressPolizas += .1
        },

        //CONSULTAMOS LAS POLIZAS
        async getPolizas() {
            this.loadingTabla = true
            this.banderaEdit = true;
            this.banderaUnirPolizas = true;
            let fI = moment(this.fechaI).format('YYYY-MM-DD')
            let fF = moment(this.fechaF).format('YYYY-MM-DD')

            if (this.tipoPoliza === "Todos") {
                await this.GetPolizasTodos(fI, fF);
                await this.GetPolizaRfc(fI, fF);
            } else {
                await this.GetPolizasTipo(fI, fF, this.tipoPoliza);
            }
            this.loadingTabla = false

        },

        async GetPolizaNivel() {
            this.loadingTabla = true
            this.banderaUnirPolizas = false;
            this.banderaEdit = false;
            let fI = moment(this.fechaI).format('YYYY-MM-DD')
            let fF = moment(this.fechaF).format('YYYY-MM-DD')

            if (this.tipoPoliza === "Todos") {
                await this.GetPolizasTodos(fI, fF);
            } else {
                await this.GetPolizasTipo(fI, fF, this.tipoPoliza);
            }
            this.loadingTabla = false;
        },

        async GetPolizasTodos(fechaI, fechaF) {
            try {
                this.$store.state.listaPolizasStore = [];
                let response = await axios.get("Polizas/GetPolizasAsync/erp_" + this.token.rfc + '/' + fechaI + '/' + fechaF);
                this.$store.state.listaPolizasStore = response.data;
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        },

        async GetPolizasTipo(fechaI, fechaF, tipo) {
            try {
                this.$store.state.listaPolizasStore = [];
                let response = await axios.get("Polizas/GetPolizasTipoAsync/erp_" + this.token.rfc + '/' + fechaI + '/' + fechaF + '/' + tipo);
                this.$store.state.listaPolizasStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async GetPolizaId(id) {
            try {
                let response = await axios.get("Polizas/GetPolizasIdAsync/erp_" + this.token.rfc + '/' + id);
                let poliza = response.data;
                return poliza
            } catch (error) {
                console.log(error)
            }
        },

        async GetPolizaIdDos(id) {
            try {
                let response = await axios.get("Polizas/GetPolizasIdDosAsync/erp_" + this.token.rfc + '/' + id);
                let poliza = response.data;
                return poliza
            } catch (error) {
                console.log(error)
            }
        },

        async GetPolizaRfc(fechaI, fechaF) {
            try {
                this.itemsRfcNombre = [];
                this.itemsPolizasRfc = [];
                let response = await axios.get("Polizas/GetPolizasRfcAsync/erp_" + this.token.rfc + '/' + fechaI + '/' + fechaF);
                this.itemsPolizasRfc = response.data;
                const x = response.data;
                const distinctItems = x.filter((item, index, self) =>
                    index === self.findIndex((t) => t.completo === item.completo)
                );
                const ordenado = distinctItems.sort((a, b) => a.nombre.localeCompare(b.nombre));
                this.itemsRfcNombre = [...ordenado];
                this.itemsPolizasRfc = [...x];
            } catch (error) {
                console.log(error)
            }
        },

        // EDITAR
        async PutEstadoPoliza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Cambiando estado de la Póliza. Espere...', messageColor: 'white' })
            let poliza = this.$store.state.polizaStore
            let estado = ''
            if (this.accion == 'eliminar') {
                estado = 'Eliminado'
            }
            else {
                estado = 'Cancelado'
            }
            try {
                let response = await axios.put('Polizas/PutEstadoPoliza/erp_' + this.token.rfc + '/' + poliza._id + '/' + estado)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                if (this.accion == 'eliminar') {
                    let indice = this.itemPolizas.indexOf(poliza)
                    this.itemPolizas.splice(indice, 1)

                    // console.log(poliza.listaXml)
                    // //QUITAMOS DE LA LISTA DE XML CONTABILIZADOS
                    // for (let x of poliza.listaXml) {
                    //     let delete_ = await axios.delete('Polizas/DeleteXmlContabilizadoAsync/erp_' + this.token.rfc + '/' + x.folioFiscal)
                    // }
                }
                // SI SE CANCELA SOLO EDITAMOS EL OBJETO
                else {
                    poliza.estatus = 'Cancelado'
                    let indice = this.$store.state.listaPolizasStore.findIndex(x => x._id === poliza._id);
                    Object.assign(this.$store.state.listaPolizasStore[indice], poliza)
                }

                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El estado de la Póliza ha cambiado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        async PutActivarPoliza(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Activando la Póliza. Espere...', messageColor: 'white' })
            let poliza = item

            try {
                let response = await axios.put('Polizas/PutEstadoPoliza/erp_' + this.token.rfc + '/' + poliza._id + '/Activo')
                // console.log(response)

                poliza.estatus = 'Activo'
                let indice = this.$store.state.listaPolizasStore.findIndex(x => x._id === poliza._id);
                Object.assign(this.$store.state.listaPolizasStore[indice], poliza)

                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El estado de la Póliza ha cambiado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        async PutValidarPoliza(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Validando Póliza. Espere...', messageColor: 'white' })
            let poliza = item

            try {
                let response = await axios.put('Polizas/PutEstadoPoliza/erp_' + this.token.rfc + '/' + poliza._id + '/Validada')
                // console.log(response)

                poliza.estatus = 'Validada'
                let indice = this.$store.state.listaPolizasStore.findIndex(x => x._id === poliza._id);
                Object.assign(this.$store.state.listaPolizasStore[indice], poliza)

                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El estado de la Póliza ha cambiado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        async verPoliza(item) {
            this.loadingTabla = true
            if (this.banderaUnirPolizas) {
                let id = item._id;
                let poliza = await this.GetPolizaId(id)
                this.dialogPDF = true;
                this.loadingTabla = false
                this.$nextTick(() => {
                    this.$store.state.vistaPreviaStore.color = "19775C"
                    this.$store.state.vistaPreviaStore.tipo = "POLIZA"
                    this.$refs.miComponenteHijo.VerPolizaPdf(poliza);
                }
                );
            } else {
                let id = item._id;
                let poliza = await this.GetPolizaIdDos(id)
                this.dialogPDF = true;
                this.loadingTabla = false
                this.$nextTick(() => {
                    this.$store.state.vistaPreviaStore.color = "19775C"
                    this.$store.state.vistaPreviaStore.tipo = "POLIZA"
                    this.$refs.miComponenteHijo.VerPolizaPdf(poliza);
                }
                );
            }
            this.loadingTabla = false
        },

        async editarPoliza(data) {
            this.loadingTabla = true
            let id = data._id;
            try {
                let response = await axios.get("Polizas/GetPolizasIdAsync/erp_" + this.token.rfc + '/' + id);
                console.log(response.data);
                this.$store.state.polizaStore = { ...response.data }
                this.$store.state.indexPolizas = this.$store.state.listaPolizasStore.indexOf(data)
                this.dialogNuevaPoliza = true
                this.loadingTabla = false
            } catch (error) {
                console.log(error)
                this.loadingTabla = false
            }
        },

        confirm(item, accion) {
            this.accion = accion
            this.$store.state.polizaStore = item
            this.dialogConfirm = true

            console.log(item);
        },

        async nuevaPoliza() {
            this.dialogNuevaPoliza = true
            await this.iniciaizarObjetoNuevaPoliza();
        },

        nuevaPolizaCierre() {
            this.dialogNuevaPolizaCierre = true
        },

        abrirDialogGenerarPolizas() {
            this.dialogGenerarPolizas = true
        },

        abrirCancelarPoliza() {
            this.dialogCancelarPolizas = true
        },
        // DESCARGAR LOTE DE PDF
        async descargarLote() {
            try {

                if (this.selectedPolizas.length == 0) {
                    this.$q.notify({ type: 'info', message: `Seleccione al menos una Póliza.` })
                    return;
                }

                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando PDF. Espere...', messageColor: 'white' })

                var zip = new JSZip();
                for (var x of this.selectedPolizas) {
                    let color = this.empresa.color;
                    let poliza = null;
                    if (this.banderaUnirPolizas) {
                        poliza = await this.GetPolizaId(x._id);
                    } else {
                        poliza = await this.GetPolizaIdDos(x._id);
                    }
                    let base64 = await PolizaPDF(poliza, this.empresa, color);
                    let ObjPdf = base64.split(',')[1];

                    let nombre = x.tipo + ' ' + x.numPoliza;
                    let archivo2 = this.b64toBlob(ObjPdf, 'pdf');
                    zip.file("Polizas/" + nombre + ".pdf", archivo2);

                }
                let fI = moment(this.fechaI).format('YYYY-MM-DD')
                let fF = moment(this.fechaF).format('YYYY-MM-DD')

                let nombreArchivo = 'Polizas_' + this.empresa.rfc + '_' + fI + '_' + fF + '_' + this.tipoPoliza
                zip.generateAsync({ type: "blob" })
                    .then(function (content) {
                        var url = URL.createObjectURL(content);
                        var link = document.createElement('a');
                        link.href = url;
                        link.download = nombreArchivo + ".zip";
                        document.body.appendChild(link);
                        link.click();
                        URL.revokeObjectURL(url);
                    });

                this.selectedPolizas = [];
                this.$q.loading.hide()

                this.$q.notify({ type: 'positive', message: `Se ha generado la descarga.` })
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: `Error al generar descarga.` })

            }

        },

        b64toBlob(base64, tipo) {
            var binary = atob(base64);
            var length = binary.length;
            var buffer = new ArrayBuffer(length);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < length; i++) {
                view[i] = binary.charCodeAt(i);
            }
            return new Blob([buffer], { type: tipo });
        },

        FormatDate(value) {
            let fecha = new Date(value);
            fecha = new Date(fecha.getTime() + fecha.getTimezoneOffset() * 60000);
            const formato = "dd-MMMM-yyyy";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        async iniciaizarObjetoNuevaPoliza() {
            const fechaConZonaHoraria = new Date(this.fechaF);
            const fechaSinZonaHoraria = new Date(fechaConZonaHoraria.getFullYear(), fechaConZonaHoraria.getMonth(), fechaConZonaHoraria.getDate(), fechaConZonaHoraria.getHours(), fechaConZonaHoraria.getMinutes(), fechaConZonaHoraria.getSeconds());
            let fe = fechaSinZonaHoraria.toString();
            let feD = new Date(fe);
            // console.log(feD);
            let nuevaPoliza = {
                _id: '',
                tipo: 'Diario',
                fecha: subDays(feD, 1),
                descripcion: '',
                numPoliza: 0,
                asientos: [],
                listaXml: [

                ],
                estatus: 'Activo',
                abono: 0,
                cargo: 0,
                diferencia: 0,
            }
            this.$store.state.polizaStore = nuevaPoliza
        },

        CloseDialogPdf() {
            this.dialogPDF = false;
        },

        //FOLIADO
        OpenDialogFoliado() {
            this.dialogFoliado = true;
        },

        async ConfirmFoliado() {
            if (this.selectFoliado === 'Todos') {
                await this.FoliadoEgresos();
                await this.FoliadoIngresos();
                await this.FoliadoDiario();
                this.$q.loading.hide()
            } else if (this.selectFoliado === 'Ingreso') {
                await this.FoliadoIngresos();
                this.$q.loading.hide()
            } else if (this.selectFoliado === 'Egreso') {
                await this.FoliadoEgresos();
                this.$q.loading.hide()
            } else if (this.selectFoliado === 'Diario') {
                await this.FoliadoDiario();
                this.$q.loading.hide()
            }

        },

        async FoliadoEgresos() {
            this.$q.loading.show({
                message: 'Reasignando folios de Egresos...'
            })
            let polizas = this.itemPolizas.filter(f => f.tipo === "Egreso");
            let numeroPoliza = 1;
            for (let p of polizas) {
                p.numPoliza = numeroPoliza;
                try {
                    let response = await axios.put('Polizas/PutNumPolizaAsync/erp_' + this.token.rfc + '/' + p._id + '/' + p.numPoliza);
                } catch (error) {
                    console.log(error)
                }
                numeroPoliza++;
            }
        },

        async FoliadoIngresos() {
            this.$q.loading.show({
                message: 'Reasignando folios de Ingresos...'
            })
            let polizas = this.itemPolizas.filter(f => f.tipo === "Ingreso");
            let numeroPoliza = 1;
            for (let p of polizas) {
                p.numPoliza = numeroPoliza;
                try {
                    let response = await axios.put('Polizas/PutNumPolizaAsync/erp_' + this.token.rfc + '/' + p._id + '/' + p.numPoliza);
                } catch (error) {
                    console.log(error)
                }
                numeroPoliza++;
            }
        },

        async FoliadoDiario() {
            this.$q.loading.show({
                message: 'Reasignando folios de Diario...'
            })
            let polizas = this.itemPolizas.filter(f => f.tipo === "Diario");
            let numeroPoliza = 1;
            for (let p of polizas) {
                p.numPoliza = numeroPoliza;
                try {
                    let response = await axios.put('Polizas/PutNumPolizaAsync/erp_' + this.token.rfc + '/' + p._id + '/' + p.numPoliza);
                } catch (error) {
                    console.log(error)
                }
                numeroPoliza++;
            }
        },

        //UNIR POLIZAS
        OpenDialogUnirPolizas() {
            //VALIDAMOS QUE TENGA AL MENOS UNA POLIZA SELECCIONADA
            if (this.selectedPolizas.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione al menos dos pólizas.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
                return;
            }

            if (this.selectedPolizas.length == 1) {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione al menos dos pólizas.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
                return;
            }

            //VALIDAMOS QUE NO TENGA CANCELADAS
            for (let a of this.selectedPolizas) {
                if (a.estatus === "Cancelado") {
                    this.$q.notify({
                        type: 'negative',
                        message: `No se puede fusionar una póliza cancelada`,
                        actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                    })
                    return;
                }
            }
            this.$store.state.listaPolizasUnir = [];
            this.dialogUnirPolizas = true;
        },

        async UnirPolizasAsientos() {
            this.loadingTabla = true
            const objP = this.selectedPolizas[0];
            const fechaConZonaHoraria = new Date(objP.fecha);
            const fechaSinZonaHoraria = new Date(fechaConZonaHoraria.getFullYear(), fechaConZonaHoraria.getMonth(), fechaConZonaHoraria.getDate(), fechaConZonaHoraria.getHours(), fechaConZonaHoraria.getMinutes(), fechaConZonaHoraria.getSeconds());
            let fe = fechaSinZonaHoraria.toString();
            let feD = new Date(fe);
            let preAsientos = [];
            let preListaXml = [];

            //CONSULTAMOS LAS POLIZAS
            let listaPolizas = [];
            for (let a of this.selectedPolizas) {
                let poliza = await this.GetPolizaId(a._id);
                listaPolizas.push(poliza);
            }

            // for (let a of this.selectedPolizas) {
            for (let a of listaPolizas) {
                this.$store.state.listaPolizasUnir.push(a._id)
                for (let b of a.asientos) {
                    preAsientos.push(b)
                }
                for (let c of a.listaXml) {
                    preListaXml.push(c);
                }
            }
            let asientos = [...preAsientos];
            let listaXml = [...new Set(preListaXml)];
            let nuevaPoliza = {
                _id: '',
                tipo: objP.tipo,
                fecha: feD,
                descripcion: '',
                numPoliza: 0,
                asientos: asientos,
                listaXml: listaXml,
                estatus: 'Activo',
                abono: 0,
                cargo: 0,
                diferencia: 0,
            }
            this.$store.state.polizaStore = nuevaPoliza
            this.dialogUnirPolizas = false;
            this.dialogNuevaPoliza = true
            this.selectedPolizas = [];
            this.loadingTabla = false;
        },

        async UnirPolizasAsientosB() {
            this.loadingTabla = true
            const objP = this.selectedPolizas[0];
            const fechaConZonaHoraria = new Date(objP.fecha);
            const fechaSinZonaHoraria = new Date(fechaConZonaHoraria.getFullYear(), fechaConZonaHoraria.getMonth(), fechaConZonaHoraria.getDate(), fechaConZonaHoraria.getHours(), fechaConZonaHoraria.getMinutes(), fechaConZonaHoraria.getSeconds());
            let fe = fechaSinZonaHoraria.toString();
            let feD = new Date(fe);
            let preAsientos = [];
            let preListaXml = [];

            //CONSULTAMOS LAS POLIZAS
            let listaPolizas = [];
            for (let a of this.selectedPolizas) {
                let poliza = await this.GetPolizaId(a._id);
                listaPolizas.push(poliza);
            }

            for (let a of listaPolizas) {
                this.$store.state.listaPolizasUnir.push(a._id)
                for (let b of a.asientos) {
                    preAsientos.push(b)
                }
                for (let c of a.listaXml) {
                    preListaXml.push(c);
                }
            }
            let asientos = await this.AgrupaYCalculaAsientos(preAsientos);
            let listaXml = [...new Set(preListaXml)];
            let nuevaPoliza = {
                _id: '',
                tipo: objP.tipo,
                fecha: feD,
                descripcion: '',
                numPoliza: 0,
                asientos: asientos,
                listaXml: listaXml,
                estatus: 'Activo',
                abono: 0,
                cargo: 0,
                diferencia: 0,
            }
            this.$store.state.polizaStore = nuevaPoliza
            this.dialogUnirPolizas = false;
            this.dialogNuevaPoliza = true
            this.selectedPolizas = [];
            this.loadingTabla = false;
        },

        async AgrupaYCalculaAsientos(lista) {
            var agrupado = lista.reduce(function (acumulador, elemento) {
                // Crear una clave única para el grupo
                var clave = elemento.cuenta.numCuentaUnico;

                // Inicializar el grupo si es la primera vez que se encuentra la clave
                if (!acumulador[clave]) {
                    acumulador[clave] = {
                        cuenta: elemento.cuenta,
                        descripcion: elemento.descripcion,
                        cargo: 0,
                        abono: 0,
                    };
                }

                // Sumar base e importe al grupo correspondiente
                acumulador[clave].cargo += parseFloat(elemento.cargo);
                acumulador[clave].abono += parseFloat(elemento.abono);

                return acumulador;
            }, {});

            // Convertir el objeto agrupado de nuevo a una lista
            var resultado = Object.values(agrupado);

            for (let r of resultado) {
                r.cargo = parseFloat(r.cargo.toFixed(2));
                r.abono = parseFloat(r.abono.toFixed(2));
            }
            return resultado;
        },

        //FILTRADO AVANZADO
        OpenDialogDiltradoAvanzado() {
            this.dialogFiltroAvanzado = true;
        },

        filtroRfcNombre(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroRfcNombres = this.itemsRfcNombre.filter(v => v.completo.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroRfcNombres.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        BusquedaAvanzada() {
            if (!this.soloFolioFiscal) {
                if (this.selectFolioFiscal === "") {
                    this.BusquedaPorRfcNombre();
                }
            }
            if (this.soloFolioFiscal) {
                this.BusquedaPorFolioFiscal();
            }
        },

        BusquedaPorRfcNombre() {
            if (!this.selectRfcNombre) {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione una opción de la lista.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando espere...', messageColor: 'white' });

            const listaPolizas = [...this.$store.state.listaPolizasStore]
            const listaRfc = [...this.itemsPolizasRfc]
            const listaRfcFiltrada = listaRfc.filter(f => f.completo === this.selectRfcNombre.completo)
            const listaFiltrada = listaPolizas.filter(item1 => listaRfcFiltrada.some(item2 => item1._id === item2._id));
            this.$store.state.listaPolizasStore = [...listaFiltrada]
            this.$q.loading.hide()
            this.dialogFiltroAvanzado = false;
        },

        BusquedaPorFolioFiscal() {
            console.log("AAA")
            if (this.selectFolioFiscal === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique al menos un carater en el folio fiscal`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
                return;
            }
            let folioFiscal = this.selectFolioFiscal.toUpperCase();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando espere...', messageColor: 'white' });
            let listaFiltrada = [];
            for (let a of this.itemPolizas) {
                if (a.listaXml) {
                    if (a.listaXml.length != 0) {
                        for (let b of a.listaXml) {
                            if (b.folioFiscal.includes(folioFiscal)) {
                                listaFiltrada.push(a);
                            }
                        }
                    }
                }
            }
            const listaUnica = Object.values(listaFiltrada.reduce((acc, obj) => {
                acc[obj._id] = obj;
                return acc;
            }, {}));

            console.log(listaUnica);
            this.$store.state.listaPolizasStore = [...listaUnica]
            this.$q.loading.hide()
            this.dialogFiltroAvanzado = false;
        },

        //POLIZAS FUERA DEL PERIODO
        ConservarPolizaF(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Guardando...', messageColor: 'white' });
            console.log(item);
            const indice = this.itemsFueraMes.indexOf(item);
            this.itemsFueraMes.splice(indice, 1);
            this.$q.loading.hide();
        },

        ConservarTodasLasPolizas() {
            this.itemsFueraMes = []
            this.itemsFueraMesSelected = []
            this.dialogFueraMes = false;
        },

        ConservarPolizasSeleccionadas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Guardando...', messageColor: 'white' });
            for (let item of this.itemsFueraMesSelected) {
                const indice = this.itemsFueraMes.indexOf(item);
                this.itemsFueraMes.splice(indice, 1);
            }
            this.itemsFueraMesSelected = [];

            this.$q.loading.hide();
        },

        async EliminarPolizaF(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Eliminando póliza...', messageColor: 'white' });
            const idPoliza = item._id;
            console.log(idPoliza);
            //ELIMINAMOS LA POLIZA
            const elimina = await this.FuncionEliminaPoliza(idPoliza);
            //ELIMINAMOS LOS XML CONTABILIZADOS
            for (var x of item.listFolioFiscal) {
                const eliminaXml = await this.FuncionEliminaXmlContabilizado(x);
            }
            if (elimina) {
                const indice = this.itemsFueraMes.indexOf(item);
                this.itemsFueraMes.splice(indice, 1);
                this.$q.notify({
                    type: 'positive',
                    message: `Póliza eliminada.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
            } else {
                this.$q.notify({
                    type: 'negative',
                    message: `Error al eliminar, intente nuevamente.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
            }
            this.$q.loading.hide();
        },

        async EliminarTodasLasPolizas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Eliminando póliza...', messageColor: 'white' });
            this.itemsFueraMesSelected = []
            for (let item of this.itemsFueraMes) {
                const idPoliza = item._id;
                console.log(idPoliza);
                //ELIMINAMOS LA POLIZA
                const elimina = await this.FuncionEliminaPoliza(idPoliza);
                //ELIMINAMOS LOS XML CONTABILIZADOS
                for (var x of item.listFolioFiscal) {
                    const eliminaXml = await this.FuncionEliminaXmlContabilizado(x);
                }
                if (elimina) {
                    const indice = this.itemsFueraMes.indexOf(item);
                    this.itemsFueraMes.splice(indice, 1);
                }
            }
            this.$q.loading.hide();
            if (this.itemsFueraMes.length == 0) {
                this.dialogFueraMes = false;
                this.$q.notify({
                    type: 'info',
                    message: `Proceso terminado.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
            } else {
                this.$q.notify({
                    type: 'info',
                    message: `Las siguientes pólizas no se pudiron eliminar correctamente, intente nuevamente.`,
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
                })
            }
        },

        async EliminarPolizasSeleccionadas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Eliminando póliza...', messageColor: 'white' });
            for (let item of this.itemsFueraMesSelected) {
                const idPoliza = item._id;
                console.log(idPoliza);
                //ELIMINAMOS LA POLIZA
                const elimina = await this.FuncionEliminaPoliza(idPoliza);
                //ELIMINAMOS LOS XML CONTABILIZADOS
                for (var x of item.listFolioFiscal) {
                    const eliminaXml = await this.FuncionEliminaXmlContabilizado(x);
                }
                if (elimina) {
                    const indice = this.itemsFueraMes.indexOf(item);
                    this.itemsFueraMes.splice(indice, 1);
                }
            }

            this.$q.loading.hide();
            this.$q.notify({
                type: 'info',
                message: `Proceso terminado.`,
                actions: [{ label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }]
            })

        },

        async FuncionEliminaPoliza(idPoliza) {
            try {
                let response = await axios.delete("Polizas/DeletePolizaDefinitivoAsync/" + this.token.rfc + '/' + idPoliza);
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        },

        async FuncionEliminaXmlContabilizado(xml) {
            try {
                let response = await axios.delete("Polizas/DeleteXmlContabilizadoAsync/" + this.token.rfc + '/' + xml);
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        },

    }
}
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}

.Activo {
    background: #2196F3 !important;
    color: #fff !important;
}

.Cancelado {
    background: #C10015 !important;
    color: #fff !important;
}

.Validada {
    background: #4CAF50 !important;
    color: #fff !important;
}
</style>

<style lang="sass">

  .my-sticky-column-table 
    th:first-child,
    td:first-child,
    th:nth-child(2),
    td:nth-child(2) 
      background-color: #fff;
    
    th:first-child,
    td:first-child 
      position: sticky;
      left: 0;
      z-index: 1;
  
    th:nth-child(2),
    td:nth-child(2) 
      position: sticky;
      left: 72px;
      z-index: 1; 
</style>
