<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-9">
                <div class=" text-h5">Provisionales de ISR</div>
            </div>

            <div class="col-12 col-md-3">
                <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año">
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-calculator">
                            <q-tooltip>
                                Calcular
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>
        </div>

        <div class="row q-col-gutter-sm">
            <div class="col-12 col-md-2">
                <q-input filled label="Coeficiente de Utilidad Enero - Febrero" type="number" dense></q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input filled label="Coeficiente de Utilidad Mazo - Diciembre" type="number" dense></q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input filled label="Amortización de pérdidas anteriores" type="number" dense></q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input filled label="Tasa de impuestos" type="number" dense></q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input filled label="Retenciones bancarias" type="number" dense>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-content-save">
                            <q-tooltip>
                                Guardar
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>

        <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" row-key="_id">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'

export default {
    components: {},
    data() {
        return {
            itemsAnios: ['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            selectedAnio: '2025',

            loadingTabla: false,
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 2,
                rowsPerPage: 10
            },

            columns: [
                { name: 'name1', align: 'center', label: 'Mes', field: 'name', sortable: true },
                { name: 'name3', align: 'center', label: 'Ingresos', field: 'name', sortable: true },
                { name: 'name4', align: 'center', label: 'Devoluciones, descuentos o bonificaciones sobre ingresos', field: 'name', sortable: true },
                { name: 'name5', align: 'center', label: 'Otros ingresos', field: 'name', sortable: true },
                { name: 'name6', align: 'center', label: 'Productos financieros', field: 'name', sortable: true },
                { name: 'name7', align: 'center', label: 'Otros productos', field: 'name', sortable: true },
                { name: 'name8', align: 'center', label: 'PTU Pagada en el ejercicio', field: 'name', sortable: true },
                { name: 'name9', align: 'center', label: 'Total por mes', field: 'name', sortable: true },
                { name: 'name10', align: 'center', label: 'Total de ingresos acumulables', field: 'name', sortable: true },
                { name: 'name11', align: 'center', label: 'Coeficiente de utilidad', field: 'name', sortable: true },
                { name: 'name12', align: 'center', label: 'Utilidad Fiscal Estimada', field: 'name', sortable: true },
                { name: 'name13', align: 'center', label: 'Amortización de pérdidas anteriores', field: 'name', sortable: true },
                { name: 'name14', align: 'center', label: 'Base de pago provisional', field: 'name', sortable: true },
                { name: 'name15', align: 'center', label: 'Tasa de impuestos', field: 'name', sortable: true },
                { name: 'name16', align: 'center', label: 'Impuesto determinado', field: 'name', sortable: true },
                { name: 'name17', align: 'center', label: 'Pagos provisionales efectuados con anterioridad', field: 'name', sortable: true },
                { name: 'name18', align: 'center', label: 'Retenciones Bancareas', field: 'name', sortable: true },
                { name: 'name19', align: 'center', label: 'Pago provisional a enterar', field: 'name', sortable: true },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

    },
    created() {
    },
    methods: {

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>

<style></style>