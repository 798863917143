<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-9">
                <div class=" text-h5">Concialiación Contable Fiscal</div>
            </div>

            <div class="col-12 col-md-3">
                <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año">
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetConciliacionContable()">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>
        </div>

        <div class="row q-col-gutter-sm">
            <div class="col-12">
                <q-list class="rounded-borders">

                    <!-- CONCILIACIÓN CONTABLE FISCAL -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item1" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CONCILIACIÓN CONTABLE FISCAL
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :hide-bottom="true" :data="itemsEstadoResultado"
                            full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- INGRESOS FISCALES NO CONTABLES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item2" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INGRESOS FISCALES NO CONTABLES
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsIngresosFiscalesNC" :hide-bottom="true"
                            full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- DEDUCCIONES CONTABLES NO FISCALES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item3" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                DEDUCCIONES CONTABLES NO FISCALES
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsDeduccionesContablesNF"
                            :hide-bottom="true" full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- DEDUCCIONES FISCALES NO CONTABLES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item4" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                DEDUCCIONES FISCALES NO CONTABLES
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsDeduccionesFiscalesNC"
                            :hide-bottom="true" full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- INGRESOS CONTABLES NO FISCALES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item5" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INGRESOS CONTABLES NO FISCALES
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsIngresosContablesNF"
                            :hide-bottom="true" full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- UTILIDAD O PÉRDIDA FISCAL ANTES DE PTU -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item6" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                UTILIDAD O PÉRDIDA FISCAL ANTES DE PTU
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsUtilidadPerdida" :hide-bottom="true"
                            full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- AMORTIZACIÓN DE PÉRDIDAS DE EJERCICIOS ANTERIORES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item7" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                AMORTIZACIÓN DE PÉRDIDAS DE EJERCICIOS ANTERIORES
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsAmortizacion" :hide-bottom="true"
                            full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- TASA DE ISR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item8" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                TASA DE ISR
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsTasaISR" :hide-bottom="true" full-width
                            row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- DETERMINACIÓN ANUAL DE ISR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item9" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                DETERMINACIÓN ANUAL DE ISR
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsDeterminacionAnualISR"
                            :hide-bottom="true" full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- PAGOS PROVISIONALES Y/O RETENCIONES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item10" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                PAGOS PROVISIONALES Y/O RETENCIONES
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(0) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" :columns="columns" :data="itemsPagosProvisionalesRetenciones"
                            :hide-bottom="true" full-width row-key="descripcion">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                </q-list>
            </div>
        </div>

    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {
    components: {},
    data() {
        return {
            itemsAnios: ['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            selectedAnio: '2025',

            item1: false,
            item2: false,
            item3: false,
            item4: false,
            item5: false,
            item6: false,
            item7: false,
            item8: false,
            item9: false,
            item10: false,

            columns: [
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
            ],

            itemsEstadoResultado: [
                { descripcion: 'UTILIDAD O PÉRDIDA NETA', total: 0 },
                { descripcion: 'EFECTOS DE REEXPRESIÓN', total: 0 },
                { descripcion: 'RESULTADO POR POSICIÓN MONETARIA', total: 0 },
                { descripcion: 'UTILIDAD O PÉRDIDA NETA HISTÓRICA', total: 0 },
            ],

            itemsIngresosFiscalesNC: [
                { descripcion: 'AJUSTE ANUAL POR INFLACIÓN ACUMULABLE', total: 0 },
                { descripcion: 'ANTICIPOS DE CLIENTES', total: 0 },
                { descripcion: 'INTERESES MORATORIOS EFECTIVAMENTE COBRADOS', total: 0 },
                { descripcion: 'GANANCIA EN LA ENAJENACIÓN DE ACCIONES O POR REEMBOLSO DE CAPITAL', total: 0 },
                { descripcion: 'GANANCIA EN LA ENAJENACIÓN DE TERRENOS Y ACTIVO FIJO', total: 0 },
                { descripcion: 'INVENTARIO ACUMULABLE DEL EJERCICIO', total: 0 },
                { descripcion: 'OTROS INGRESOS FISCALES NO CONTABLES', total: 0 },
            ],

            itemsDeduccionesContablesNF: [
                { descripcion: 'COSTO DE VENTAS CONTABLE', total: 0 },
                { descripcion: 'DEPRECIACIÓN Y AMORTIZACIÓN CONTABLE', total: 0 },
                { descripcion: 'GASTOS QUE NO REÚNEN REQUISITOS FISCALES', total: 0 },
                { descripcion: 'ISR, IETU, IMPAC Y PTU', total: 0 },
                { descripcion: 'PÉRDIDA CONTABLE EN ENAJENACIÓN DE ACCIONES', total: 0 },
                { descripcion: 'PÉRDIDA CONTABLE EN ENAJENACIÓN DE ACTIVO FIJO', total: 0 },
                { descripcion: 'PÉRDIDA EN PARTICIPACIÓN SUBSIDIARIA', total: 0 },
                { descripcion: 'INTERESES DEVENGADOS QUE EXCEDEN DEL VALOR DE MERCADO Y MORATORIOS PAGADOS O NO', total: 0 },
                { descripcion: 'OTRAS DEDUCCIONES CONTABLES NO FISCALES	', total: 0 },
            ],

            itemsDeduccionesFiscalesNC: [
                { descripcion: 'AJUSTE ANUAL POR INFLACIÓN DEDUCIBLE', total: 0 },
                { descripcion: 'COSTO DE LO VENDIDO FISCAL', total: 0 },
                { descripcion: 'DEDUCCIÓN DE INVERSIONES', total: 0 },
                { descripcion: 'ESTÍMULO FISCAL POR DEDUCCIÓN INMEDIATA DE INVERSIONES', total: 0 },
                { descripcion: 'ESTÍMULO FISCAL POR CONTRATAR PERSONAS CON DISCAPACIDAD Y/O ADULTOS MAYORES', total: 0 },
                { descripcion: 'DEDUCCIÓN DEL IMPUESTO SOBRE LA RENTA RETENIDO A PERSONAS CON DISCAPACIDAD Y/O ADULTOS MAYORES', total: 0 },
                { descripcion: 'PÉRDIDA FISCAL EN ENAJENACIÓN DE ACCIONES', total: 0 },
                { descripcion: 'PÉRDIDA FISCAL EN ENAJENACIÓN DE TERRENOS Y ACTIVO FIJO', total: 0 },
                { descripcion: 'INTERESES MORATORIOS EFECTIVAMENTE PAGADOS', total: 0 },
                { descripcion: 'OTRAS DEDUCCIONES FISCALES NO CONTABLES', total: 0 },
            ],

            itemsIngresosContablesNF: [
                { descripcion: 'INTERESES MORATORIOS DEVENGADOS A FAVOR COBRADOS O NO', total: 0 },
                { descripcion: 'ANTICIPOS DE CLIENTES DE EJERCICIOS ANTERIORES', total: 0 },
                { descripcion: 'SALDOS A FAVOR DE IMPUESTOS Y SU ACTUALIZACIÓN', total: 0 },
                { descripcion: 'UTILIDAD CONTABLE EN ENAJENACIÓN DE ACTIVO FIJO', total: 0 },
                { descripcion: 'UTILIDAD CONTABLE EN ENAJENACIÓN DE ACCIONES', total: 0 },
                { descripcion: 'UTILIDAD EN PARTICIPACIÓN SUBSIDIARIA', total: 0 },
                { descripcion: 'OTROS INGRESOS CONTABLES NO FISCALES', total: 0 },
            ],

            itemsUtilidadPerdida: [
                { descripcion: 'UTILIDAD O PÉRDIDA FISCAL ANTES DE PTU', total: 0 },
            ],

            itemsAmortizacion: [
                { descripcion: 'RESULTADO DEL EJERCICIO', total: 0 },
            ],

            itemsTasaISR: [
                { descripcion: 'TASA DEL ISR', total: 0 }
            ],

            itemsDeterminacionAnualISR: [
                { descripcion: 'DETERMINACIÓN ISR ANUAL', total: 0 },
            ],

            itemsPagosProvisionalesRetenciones: [
                { descripcion: 'ISR A PAGAR EN DECLARACIÓN ANUAL', total: 0 }
            ]

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

    },
    created() {
    },
    methods: {
        async GetConciliacionContable() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 120, message: 'Generando conciliación contable fiscal. Espere...', messageColor: 'white' })

            try {
                var fechaIni = this.selectedAnio + '-01-01'
                var fechaFin = this.selectedAnio + '-12-31'
                let response = await axios.get('ReportesEspeciales/GetConciliacionContableFiscal/erp_' + this.token.rfc + '/' + fechaIni + '/' + fechaFin)
                console.log(response);

                this.itemsEstadoResultado[0].total = response.data.importeER
                this.$q.loading.hide()


            } catch (error) {
                console.log(error)
                this.$q.loading.hide()

            }
        },
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>

<style></style>