import { render, staticRenderFns } from "./CalculoAjusteAnualInflacion.vue?vue&type=template&id=20873599&scoped=true&"
import script from "./CalculoAjusteAnualInflacion.vue?vue&type=script&lang=js&"
export * from "./CalculoAjusteAnualInflacion.vue?vue&type=script&lang=js&"
import style0 from "./CalculoAjusteAnualInflacion.vue?vue&type=style&index=0&id=20873599&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20873599",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtnToggle,QSelect,QBtn,QTooltip,QCard,QCardSection,QSeparator,QTable,QTr,QTd,QTabs,QTab,QTabPanels,QTabPanel,QInnerLoading});
