<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7">
                <div class=" text-h5">Estado de situación financeira por año</div>
            </div>
            <!-- FECHA FINAL -->
            <div class="col-12 col-md-5">
                <q-select v-model="selectAño" filled label="Seleccione el año" class="q-mr-sm" name="event" outlined
                    dense :options="itemsAños">
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetReporte()">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportarExcelDos">
                            <q-tooltip>
                                Generar Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-pdf-box" @click="ExportarPdf">
                            <q-tooltip>
                                Generar PDF
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>
        </div>
        <div class="text-h5">
            ACTIVO
        </div>
        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsEstado" hide-bottom
            :rows-per-page-options="[0]" :pagination.sync="pagination" title="Activo circulante">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                    <q-td key="enero" :props="props">{{ formatCurrency(props.row.enero) }}</q-td>
                    <q-td key="febrero" :props="props">{{ formatCurrency(props.row.febrero) }}</q-td>
                    <q-td key="marzo" :props="props">{{ formatCurrency(props.row.marzo) }}</q-td>
                    <q-td key="abril" :props="props">{{ formatCurrency(props.row.abril) }}</q-td>
                    <q-td key="mayo" :props="props">{{ formatCurrency(props.row.mayo) }}</q-td>
                    <q-td key="junio" :props="props">{{ formatCurrency(props.row.junio) }}</q-td>
                    <q-td key="julio" :props="props">{{ formatCurrency(props.row.julio) }}</q-td>
                    <q-td key="agosto" :props="props">{{ formatCurrency(props.row.agosto) }}</q-td>
                    <q-td key="septiembre" :props="props">{{ formatCurrency(props.row.septiembre) }}</q-td>
                    <q-td key="octubre" :props="props">{{ formatCurrency(props.row.octubre) }}</q-td>
                    <q-td key="noviembre" :props="props">{{ formatCurrency(props.row.noviembre) }}</q-td>
                    <q-td key="diciembre" :props="props">{{ formatCurrency(props.row.diciembre) }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import { EstadoResultadosPDF } from '../../PDF/EstadoResultadosPDF.js'
import * as XLSX from 'xlsx';
import { MayoresPdf } from '../../PDF/MayoresPdf.js'

export default {
    components: {},
    data() {
        return {
            selectAño: "2025",
            itemsAños: ["2025", "2024", "2023", "2022", "2021", "2020", "2019"],
            pagination: {
                rowsPerPage: 0
            },
            columns: [
                { name: 'tipo', align: 'center', label: '', field: 'tipo', style: 'text-align: left;' },
                // { name: 'descripcion', align: 'center', label: 'Mes', field: 'descripcion', style: 'text-align: left; max-width: 100px' },
                { name: 'enero', align: 'center', label: 'Enero', field: 'enero', style: 'text-align: right;' },
                { name: 'febrero', align: 'center', label: 'Febrero', field: 'febrero', style: 'text-align: right;' },
                { name: 'marzo', align: 'center', label: 'Marzo', field: 'marzo', style: 'text-align: right;' },
                { name: 'abril', align: 'center', label: 'Abril', field: 'abril', style: 'text-align: right;' },
                { name: 'mayo', align: 'center', label: 'Mayo', field: 'mayo', style: 'text-align: right;' },
                { name: 'junio', align: 'center', label: 'Junio', field: 'junio', style: 'text-align: right;' },
                { name: 'julio', align: 'center', label: 'Julio', field: 'julio', style: 'text-align: right;' },
                { name: 'agosto', align: 'center', label: 'Agosto', field: 'agosto', style: 'text-align: right;' },
                { name: 'septiembre', align: 'center', label: 'Septiembre', field: 'septiembre', style: 'text-align: right;' },
                { name: 'octubre', align: 'center', label: 'Octubre', field: 'octubre', style: 'text-align: right;' },
                { name: 'noviembre', align: 'center', label: 'Noviembre', field: 'noviembre', style: 'text-align: right;' },
                { name: 'diciembre', align: 'center', label: 'Diciembre', field: 'diciembre', style: 'text-align: right;' },
            ],
            itemsEstado: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

    },
    created() {

    },
    methods: {
        formatCurrency(value) {
            if (value != '-') {
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                return '';
            }
        },

        async GetReporte() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando Reporte...', messageColor: 'white' })
            try {

                let itemsEnero = [];
                let itemsFebrero = [];
                let itemsMarzo = [];
                let itemsAbril = [];
                let itemsMayo = [];
                let itemsJunio = [];
                let itemsJulio = [];
                let itemsAgosto = [];
                let itemsSeptiembre = [];
                let itemsOctubre = [];
                let itemsNoviembre = [];
                let itemsDiciembre = [];

                for (let i = 0; i < 12; i++) {
                    let mes = i + 1;
                    let fechaI = this.selectAño + "-" + mes + "-01";
                    let ultimoDia = await this.UltimoDiaMes(this.selectAño, i);
                    let fechaF = this.selectAño + "-" + mes + "-" + ultimoDia;
                    console.log(fechaI, fechaF)
                    let response = await axios.get('Reportes/GetEstadoDeSituacionFinancieraAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF);
                    console.log(response.data);
                }

                //ACTIVOS
                let activosList = [];
                for (let a of activosList) {
                    objActivo = {
                        tipo: "",
                        enero: 0,
                        febrero: 0,
                        marzo: 0,
                        abril: 0,
                        mayo: 0,
                        junio: 0,
                        julio: 0,
                        agosto: 0,
                        septiembre: 0,
                        octubre: 0,
                        noviembre: 0,
                        diciembre: 0,
                    }
                    itemsEstado.push(objActivo);
                }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportarPdf() {
            if (this.itemsMayores.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero el reporte',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await MayoresPdf(this.itemsMayoresPdf, this.empresa, this.empresa.color, this.selectAño);
            const fileName = 'REPORTE DE MAYORES ' + this.token.empresa + ' PERIODO ' + this.selectAño;
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        async ExportarExcelDos() {
            if (this.itemsMayores.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero el reporte',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            let pre = [...this.itemsMayores];
            pre.forEach(e => {
                if (e.numero === "") {
                    e.descripcion = "";
                    e.saldoInicial = "";
                    e.cargos = "";
                    e.abonos = "";
                    e.saldoFinal = "";
                    e.acumuladosCargos = "";
                    e.acumuladosAbonos = "";
                }
            });
            const data = [...pre];
            const columnsToInclude = ['numero', 'descripcion', 'saldoInicial', 'cargos', 'abonos', 'saldoFinal', 'acumuladosCargos', 'acumuladosAbonos'];
            const datos = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const arrayDeValores = datos.map(objeto => Object.values(objeto));
            const libroTrabajo = XLSX.utils.book_new();
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['REPORTE DE MAYORES'],
                [this.token.empresa],
                ['PERIODO ' + this.selectAño],
                [],
                [
                    'Año',
                    'Mes',
                    'Saldo inicial',
                    'Cargos',
                    'Abonos',
                    'Saldo final',
                    'Acumulados cargos',
                    'Acumulados abonos',
                ],
                ...arrayDeValores.map((registro, index) => [
                    registro[0],
                    registro[1],
                    { t: 'n', v: registro[2], z: '#,##0.00' },
                    { t: 'n', v: registro[3], z: '#,##0.00' },
                    { t: 'n', v: registro[4], z: '#,##0.00' },
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                    { t: 'n', v: registro[6], z: '#,##0.00' },
                    { t: 'n', v: registro[7], z: '#,##0.00' },
                ]),
            ]);
            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 7, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 7, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 7, r: 2 } });

            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');
            const nombreArchivo = 'REPORTE DE MAYORES ' + this.token.empresa + ' PERIODO ' + this.selectAño;
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },

        async UltimoDiaMes(año, mes) {
            let ultimoDia = new Date(año, mes + 1, 0);
            return ultimoDia.getDate();
        },

    }
}
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}
</style>