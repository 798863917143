<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-9">
                <div class=" text-h5">Provisionales de IVA</div>
            </div>

            <div class="col-12 col-md-3">
                <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año">
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetProvisionalesIva()">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-pdf-box">
                            <q-tooltip>
                                PDF
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>
        </div>
        <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" :data="itemsReporteProvIva" row-key="_id">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="mes" :props="props">{{ props.row.mes }}</q-td>
                    <q-td key="porcentaje" :props="props">{{ props.row.porcentaje + ' %' }}</q-td>
                    <q-td key="ingresos" :props="props">{{ formatCurrency(props.row.ingresos) }}</q-td>
                    <q-td key="productosFinancieros" :props="props">{{
                    formatCurrency(props.row.productosFinancieros) }}</q-td>
                    <q-td key="ventasNetas" :props="props">{{ formatCurrency(props.row.ventasNetas) }}</q-td>
                    <q-td key="ivaVentas" :props="props">{{ formatCurrency(props.row.ivaVentas) }}</q-td>
                    <q-td key="impuestosTrasladadosCobrados" :props="props">{{
                    formatCurrency(props.row.impuestosTrasladadosCobrados) }}</q-td>
                    <q-td key="proveedores" :props="props">{{ formatCurrency(props.row.proveedores) }}</q-td>
                    <q-td key="acreedores" :props="props">{{ formatCurrency(props.row.acreedores) }}</q-td>
                    <q-td key="sumaPagos" :props="props">{{ formatCurrency(props.row.sumaPagos) }}</q-td>
                    <q-td key="comprasIvaPagadas" :props="props">{{
                    formatCurrency(props.row.comprasIvaPagadas) }}</q-td>
                    <q-td key="impuestosAcreditablesPagados" :props="props">{{
                    formatCurrency(props.row.impuestosAcreditablesPagados) }}</q-td>
                    <q-td key="determinacionProvIvaC" :props="props">{{
                    formatCurrency(props.row.determinacionProvIvaC) }}</q-td>
                    <q-td key="determinacionProvIvaF" :props="props">{{
                    formatCurrency(props.row.determinacionProvIvaF) }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'

export default {
    components: {},
    data() {
        return {
            itemsAnios: ['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            selectedAnio: '2025',

            loadingTabla: false,
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 1,
                rowsPerPage: 13
            },
            itemsReporteProvIva: [],
            columns: [
                { name: 'mes', align: 'center', label: 'Mes', field: 'mes', sortable: true },
                { name: 'porcentaje', align: 'center', label: 'Porcentaje', field: 'porcentaje', sortable: true },
                { name: 'ingresos', align: 'center', label: 'Ingresos', field: 'ingresos', sortable: true },
                { name: 'productosFinancieros', align: 'center', label: 'Productos Financieros', field: 'productosFinancieros', sortable: true },
                { name: 'ventasNetas', align: 'center', label: 'Ventas netas', field: 'ventasNetas', sortable: true },
                { name: 'ivaVentas', align: 'center', label: 'IVA de ventas', field: 'ivaVentas', sortable: true },
                { name: 'impuestosTrasladadosCobrados', align: 'center', label: 'Impuestos trasladados cobrados', field: 'impuestosTrasladadosCobrados', sortable: true },
                { name: 'proveedores', align: 'center', label: 'Proveedores', field: 'proveedores', sortable: true },
                { name: 'acreedores', align: 'center', label: 'Acreedores', field: 'acreedores', sortable: true },
                { name: 'sumaPagos', align: 'center', label: 'Suma de pagos', field: 'sumaPagos', sortable: true },
                { name: 'comprasIvaPagadas', align: 'center', label: 'Compras con IVA pagadas', field: 'comprasIvaPagadas', sortable: true },
                { name: 'impuestosAcreditablesPagados', align: 'center', label: 'Impuestos acreditables pagados', field: 'impuestosAcreditablesPagados', sortable: true },
                { name: 'determinacionProvIvaC', align: 'center', label: 'Determinación provisional de IVA a Cargo', field: 'determinacionProvIvaC', sortable: true },
                { name: 'determinacionProvIvaF', align: 'center', label: 'Determinación provisional de IVA a Favor', field: 'determinacionProvIvaF', sortable: true },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

    },
    created() {
    },
    methods: {
        async GetProvisionalesIva() {
            try {
                this.loadingTabla = true
                let response = await axios.get('ReportesEspeciales/ProvisionalIva/erp_' + this.token.rfc + '/' + this.selectedAnio)
                console.log(response);
                var array = response.data

                const sumaIngresos = array.map(item => item.ingresos).reduce((prev, curr) => prev + curr, 0);
                const sumaImpuestosTrasladadosC = array.map(item => item.impuestosTrasladadosCobrados).reduce((prev, curr) => prev + curr, 0);
                const sumaClientes = array.map(item => item.clientes).reduce((prev, curr) => prev + curr, 0);
                const sumaProveedores = array.map(item => item.proveedores).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedores = array.map(item => item.acreedores).reduce((prev, curr) => prev + curr, 0);
                const sumaImpuestosAcreditablesP = array.map(item => item.impuestosAcreditablesPagados).reduce((prev, curr) => prev + curr, 0);
                const sumaProductosFinancieros = array.map(item => item.productosFinancieros).reduce((prev, curr) => prev + curr, 0);
                const sumaIsrSueldosYSalarios = array.map(item => item.IsrSueldosYSalarios).reduce((prev, curr) => prev + curr, 0);
                const sumaIsrAsimiladosASalarios = array.map(item => item.IsrAsimiladosASalarios).reduce((prev, curr) => prev + curr, 0);
                const sumaIsrArrendamiento = array.map(item => item.IsrArrendamiento).reduce((prev, curr) => prev + curr, 0);
                const sumaIsrServiciosProfecionales = array.map(item => item.IsrServiciosProfecionales).reduce((prev, curr) => prev + curr, 0);
                const sumaImpuestosRetenidosIVA = array.map(item => item.impuestosRetenidosIVA).reduce((prev, curr) => prev + curr, 0);
                const sumaRetensionesIMSS = array.map(item => item.retensionesIMSS).reduce((prev, curr) => prev + curr, 0);
                var sumatoria = {
                    mes: 'Suma', ingresos: sumaIngresos,
                    impuestosTrasladadosCobrados: sumaImpuestosTrasladadosC,
                    clientes: sumaClientes,
                    proveedores: sumaProveedores,
                    acreedores: sumaAcreedores,
                    impuestosAcreditablesPagados: sumaImpuestosAcreditablesP,
                    productosFinancieros: sumaProductosFinancieros,
                    IsrSueldosYSalarios: sumaIsrSueldosYSalarios,
                    IsrAsimiladosASalarios: sumaIsrAsimiladosASalarios,
                    IsrArrendamiento: sumaIsrArrendamiento,
                    IsrServiciosProfecionales: sumaIsrServiciosProfecionales,
                    impuestosRetenidosIVA: sumaImpuestosRetenidosIVA,
                    retensionesIMSS: sumaRetensionesIMSS,
                };
                array.push(sumatoria);

                for (var po of array) {
                    const p = po.ingresos / sumaIngresos * 100;
                    po.porcentaje = Math.round(p * 100) / 100;

                    //OBTENER VENTAS NETAS
                    let ventasNetas = po.ingresos + po.productosFinancieros
                    po.ventasNetas = ventasNetas

                    //OBTENER IVA DE VENTAS
                    let ivaVentas = Math.round((ventasNetas * 0.16) * 100) / 100
                    po.ivaVentas = ivaVentas

                    //OBTENER CLIENTES SIN IVA
                    let clientesSinIva = Math.round((po.clientes / 1.16) * 100) / 100
                    po.clientesSinIva = clientesSinIva

                    //COMPROBACION DE LO EFECTIVAMENTE COBRADO
                    let comprobacionImpuestos = Math.round(((po.clientes / 1.16) * 0.16) * 100) / 100
                    po.comprobacionImpuestos = comprobacionImpuestos

                    //DIFERENCIA DE IVA
                    let diferenciaIva = Math.round((po.impuestosTrasladadosCobrados - comprobacionImpuestos) * 100) / 100
                    po.diferenciaIva = diferenciaIva

                    //SUMA DE PAGOS
                    let sumaPagos = po.proveedores + po.acreedores
                    po.sumaPagos = sumaPagos

                    //COMPRAS CON IVA PAGADAS
                    let comprasIvaPagadas = Math.round((po.impuestosAcreditablesPagados / 0.16) * 100) / 100
                    po.comprasIvaPagadas = comprasIvaPagadas

                    //DETERMINACION PROVISIONAL DE IVA A CARGO
                    let determinacionProvIvaC = 0
                    if (po.impuestosTrasladadosCobrados > po.impuestosAcreditablesPagados) {
                        determinacionProvIvaC = Math.round((po.impuestosTrasladadosCobrados - po.impuestosAcreditablesPagados) * 100) / 100;
                    } else
                        determinacionProvIvaC = 0;

                    po.determinacionProvIvaC = Math.round((determinacionProvIvaC) * 100) / 100

                    //DETERMINACION PROVISIONAL DE IVA A FAVOR
                    let determinacionProvIvaF = 0
                    if (po.impuestosTrasladadosCobrados < po.impuestosAcreditablesPagados) {
                        determinacionProvIvaF = Math.round((po.impuestosAcreditablesPagados - po.impuestosTrasladadosCobrados) * 100) / 100;
                    } else
                        determinacionProvIvaF = 0;
                    po.determinacionProvIvaF = Math.round((determinacionProvIvaF) * 100) / 100

                }

                this.itemsReporteProvIva = array
                console.log(this.itemsReporteProvIva)
                this.loadingTabla = false

            } catch (error) {
                console.log(error)
                this.loadingTabla = false

            }
        },
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>

<style></style>