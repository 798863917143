<template>
    <q-page class=" q-pa-lg ">

        <q-dialog full-width v-model="dialogFlujoEfectivoEstado" persistent transition-show="scale"
            transition-hide="scale">
            <FlujoEfectivoEstado @cierraVentana="dialogFlujoEfectivoEstado = false"></FlujoEfectivoEstado>
        </q-dialog>

        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-8">
                <div class=" text-h5">Flujo de Efectivo</div>
            </div>
            <div class="col-12 col-md-2">
                <q-select filled dense v-model="selectedAnioA" :options="itemsAnios" label="Año del ejercicio antiguo">
                </q-select>
            </div>
            <div class="col-12 col-md-2">
                <q-select filled dense v-model="selectedAnioR" :options="itemsAnios" label="Año del ejercicio reciente">
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetReporte()">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-document-outline" @click="GetDialogFlujo()">
                            <q-tooltip>
                                Reporte
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>

        </div>

        <div class="row q-col-gutter-sm">
            <div class="col-12">
                <q-list class="rounded-borders">

                    <!-- ACTIVOS DISPONIBLES PARA VENTA -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item1" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                ACTIVOS DISPONIBLES PARA VENTA
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(diferenciaActivosDispVent) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="itemsActivosDisponiblesVenta" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}
                                        <q-popup-edit v-model.number="props.row.primerAnio" buttons v-slot="scope">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus
                                                @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}
                                        <q-popup-edit v-model.number="props.row.segundoAnio" buttons v-slot="scope">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus
                                                @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- IMPUESTOS A LA ULTILIDAD POR PAGAR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item2" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                IMPUESTOS A LA ULTILIDAD POR PAGAR
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(diferenciaImpuestosUtilPagar) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="itemsImpuestosUtilidadXPagar" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}
                                        <q-popup-edit v-model.number="props.row.primerAnio" buttons v-slot="scope">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus
                                                @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}
                                        <q-popup-edit v-model.number="props.row.segundoAnio" buttons v-slot="scope">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus
                                                @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- RENTAS COBRADAS POR ANTICIPADO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item3" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                RENTAS COBRADAS POR ANTICIPADO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(diferenciaRentasCobradasAnticipado) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="itemsRentasCobradasXAnticipado" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}

                                    </q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}
                                        <q-popup-edit v-model.number="props.row.primerAnio" buttons v-slot="scope">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus
                                                @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}
                                        <q-popup-edit v-model.number="props.row.segundoAnio" buttons v-slot="scope">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus
                                                @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- EFECTIVO Y EQUIVALENTES DE EFECTIVO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item4" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                EFECTIVO Y EQUIVALENTES DE EFECTIVO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalEfectivoEquivalentesDeEfectivo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="EfectivoEquivalentesDeEfectivo.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- INSTRUMENTOS FINANCIERONS DE NEGOCIACION -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item5" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INSTRUMENTOS FINANCIERONS DE NEGOCIACIÓN
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalInstrumentosFinancierosNegociación) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="InstrumentosFinancierosNegociación.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CLIENTES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item6" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CLIENTES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalClientes) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="Clientes.lista" :columns="columns" full-width
                            row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CUENTAS Y DOCUMENTOS POR PAGAR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item7" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CUENTAS Y DOCUMENTOS POR COBRAR
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalCuentasDocumentosXCobrar) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="CuentasDocumentosXCobrar.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- DEUDORES DIVERSOS -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item8" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                DEUDORES DIVERSOS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalDeudoresDiversos) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="DeudoresDiversos.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- ESTIMACIÓN DE CUENTAS INCOBRABLES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item9" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                ESTIMACIÓN DE CUENTAS INCOBRABLES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalEstimaciónCuentasIncobrables) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="EstimaciónCuentasIncobrables.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- IMPUESTO POR RECUPERAR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item10" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                IMPUESTO POR RECUPERAR
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalImpuestoXRecuperar) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="ImpuestoXRecuperar.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- INVENTARIOS -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item11" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INVENTARIOS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalInventarios) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="Inventarios.lista" :columns="columns" full-width
                            row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- PAGOS ANTICIPADOS -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item12" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                PAGOS ANTICIPADOS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalPagosAnticipados) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="PagosAnticipados.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- PROPIEDADES, PLANTAS Y EQUIPO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item13" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                PROPIEDADES, PLANTAS Y EQUIPO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalPropiedadesPlantasEquipo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="PropiedadesPlantasEquipo.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CUENTAS Y DOCUMENTOS POR COBRAR A LARGO PLAZO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item14" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CUENTAS Y DOCUMENTOS POR COBRAR A LARGO PLAZO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalCuentasDocumentosXCobrarLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="CuentasDocumentosXCobrarLargoPlazo.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- ANTICIPO A PROVEEDORES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item15" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                ANTICIPO A PROVEEDORES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalAnticipoProveedores) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="AnticipoProveedores.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- ACTIVOS INTANGIBLES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item16" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                ACTIVOS INTANGIBLES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalActivosIntangibles) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="ActivosIntangibles.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- IVERSIONES EN ASOCIADAS -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item17" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                IVERSIONES EN ASOCIADAS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalInversionesAsociadas) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="InversionesAsociadas.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- INSTRUMENTOS FINANCIEROS POR COBRAR A LARGO PLAZO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item18" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INSTRUMENTOS FINANCIEROS POR COBRAR A LARGO PLAZO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalInstrumentosFinancierosXCobrarLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="InstrumentosFinancierosXCobrarLargoPlazo.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- PROVEEDORES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item19" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                PROVEEDORES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalProveedores) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :columns="columns" :data="Proveedores.lista" full-width
                            row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CUENTAS Y DOCUMENTOS POR PAGAR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item20" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CUENTAS Y DOCUMENTOS POR PAGAR
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalCuentasDocumentosXPagar) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="CuentasDocumentosXPagar.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- INSTRUMENTOS FINANCIEROS -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item21" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INSTRUMENTOS FINANCIEROS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalInstrumentosFinancieros) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottome class="shadow-0" :data="InstrumentosFinancieros.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- ACREDORES DIVERSOS -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item22" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                ACREDORES DIVERSOS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalAcreedoresDiversos) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="AcreedoresDiversos.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- ANTICIPO DE CLIENTES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item23" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                ANTICIPO DE CLIENTES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalAnticipoClientes) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="AnticipoClientes.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- PROVISIONES -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item24" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                PROVISIONES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalProvisiones) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="Provisiones.lista" :columns="columns" full-width
                            row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- IMPUESTOS POR PAGAR -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item25" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                IMPUESTOS POR PAGAR
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalImpuestosXPagar) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="ImpuestosXPagar.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CUENTAS Y DOCUMENTOS POR PAGAR A LARGO PLAZO-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item26" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CUENTAS Y DOCUMENTOS POR PAGAR A LARGO PLAZO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalCuentasDocumentosXPagarLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="CuentasDocumentosXPagarLargoPlazo.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!--INSTRUMENTOS FINANCIEROS A LARGO PLAZO-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item27" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                INSTRUMENTOS FINANCIEROS A LARGO PLAZO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalInstrumentosFinancierosLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="InstrumentosFinancierosLargoPlazo.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!--APORTACIONES PARA FUTUROS AUMENTOS DE CAPIAL-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item28" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                APORTACIONES PARA FUTUROS AUMENTOS DE CAPIAL
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalAportacionesParaFuturosAumentosCapital) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="AportacionesParaFuturosAumentosCapital.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!--IMPUESTO A LA UTILIDAD DIFERIDO POR PAGAR-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item29" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                IMPUESTO A LA UTILIDAD DIFERIDO POR PAGAR
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalImpuestoUtilidadDiferidoXPagar) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="ImpuestoUtilidadDiferidoXPagar.lista"
                            :columns="columns" full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- BENEFICIO A EMPLEADOS-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item30" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                BENEFICIO A EMPLEADOS
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalBeneficioEmpleados) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="BeneficioEmpleados.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!--PROVISIONES A LARGO PLAZO-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item31" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                PROVISIONES A LARGO PLAZO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalProvisionesLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="ProvisionesLargoPlazo.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!--INSTRUMENTOS FINANCIEROS A LARGO PLAZO-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item32" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                CAPITAL SOCIAL
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalCapitalSocial) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="CapitalSocial.lista" :columns="columns" full-width
                            row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- UTILIDADES DEL EJERCICIO-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item33" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                UTILIDADES DEL EJERCICIO
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalUtilidadesEjercicio) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="UtilidadesEjercicio.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!--  OTROS RESULTADOS INTEGRALES-->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" v-model="item34" switch-toggle-side
                        expand-separator icon="perm_identity">
                        <template v-slot:header>
                            <q-item-section>
                                OTROS RESULTADOS INTEGRALES
                            </q-item-section>
                            <q-item-section side>
                                Diferencia {{ formatCurrency(totalOtrosResultadosIntegrales) }}
                            </q-item-section>
                        </template>
                        <q-table hide-bottom class="shadow-0" :data="OtrosResultadosIntegrales.lista" :columns="columns"
                            full-width row-key="name">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                    <q-td key="primerAnio" :props="props">{{ formatCurrency(props.row.primerAnio)
                                        }}</q-td>
                                    <q-td key="segundoAnio" :props="props">{{ formatCurrency(props.row.segundoAnio)
                                        }}</q-td>
                                    <q-td key="diferencia" :props="props">{{ formatCurrency(props.row.diferencia)
                                        }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                </q-list>
            </div>
        </div>

    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import FlujoEfectivoEstado from './FlujoEfectivoEstado.vue'
import { QSpinnerCube } from 'quasar'

export default {
    components: {
        FlujoEfectivoEstado
    },
    data() {
        return {
            itemsAnios: ['2025','2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            selectedAnioA: '2025',
            selectedAnioR: '2025',
            item1: false,
            item2: false,
            item3: false,
            item4: false,
            item5: false,
            item6: false,
            item7: false,
            item8: false,
            item9: false,
            item10: false,
            item11: false,
            item11: false,
            item12: false,
            item13: false,
            item14: false,
            item15: false,
            item16: false,
            item17: false,
            item18: false,
            item19: false,
            item20: false,
            item21: false,
            item22: false,
            item23: false,
            item24: false,
            item25: false,
            item26: false,
            item27: false,
            item28: false,
            item29: false,
            item30: false,
            item31: false,
            item32: false,
            item33: false,
            item34: false,

            //Efectivo y equivalentes de efectivo
            EfectivoEquivalentesDeEfectivo: {
                nombreCuenta: 'Efectivo y equivalentes de efectivo',
                lista: []
            },
            //Instrumentos financieros de negociación
            InstrumentosFinancierosNegociación: {
                nombreCuenta: 'Instrumentos financieros de negociación',
                lista: []
            },
            //Clientes
            Clientes: {
                nombreCuenta: 'Clientes',
                lista: []
            },
            //Cuentas y documentos por cobrar
            CuentasDocumentosXCobrar: {
                nombreCuenta: 'Cuentas y documentos por cobrar',
                lista: []
            },
            //Deudores diversos
            DeudoresDiversos: {
                nombreCuenta: 'Deudores diversos',
                lista: []
            },
            //Estimación de cuentas incobrables
            EstimaciónCuentasIncobrables: {
                nombreCuenta: 'Estimación de cuentas incobrables',
                lista: []
            },
            //Impuesto por recuperar
            ImpuestoXRecuperar: {
                nombreCuenta: 'Impuesto por recuperar',
                lista: []
            },
            //Inventarios
            Inventarios: {
                nombreCuenta: 'Inventarios',
                lista: []
            },
            //Pagos anticipados
            PagosAnticipados: {
                nombreCuenta: 'Pagos anticipados',
                lista: []
            },
            //Propiedades, plantas y equipo
            PropiedadesPlantasEquipo: {
                nombreCuenta: 'Propiedades, plantas y equipo',
                lista: []
            },
            //Cuentas y documentos por cobrar a largo plazo
            CuentasDocumentosXCobrarLargoPlazo: {
                nombreCuenta: 'Cuentas y documentos por cobrar a largo plazo',
                lista: []
            },
            //Anticipo a proveedores
            AnticipoProveedores: {
                nombreCuenta: 'Anticipo a proveedores',
                lista: []
            },
            //Activos intangibles
            ActivosIntangibles: {
                nombreCuenta: 'Activos intangibles',
                lista: []
            },
            //Inversiones en asociadas
            InversionesAsociadas: {
                nombreCuenta: 'Inversiones en asociadas',
                lista: []
            },
            //Instrumentos financieros por cobrar a largo plazo
            InstrumentosFinancierosXCobrarLargoPlazo: {
                nombreCuenta: 'Instrumentos financieros por cobrar a largo plazo',
                lista: []
            },
            //Proveedores
            Proveedores: {
                nombreCuenta: 'Proveedores',
                lista: []
            },
            //Cuentas y documentos por pagar
            CuentasDocumentosXPagar: {
                nombreCuenta: 'Cuentas y documentos por pagar',
                lista: []
            },
            //Instrumentos financieros
            InstrumentosFinancieros: {
                nombreCuenta: 'Instrumentos financieros',
                lista: []
            },
            //Acreedores diversos
            AcreedoresDiversos: {
                nombreCuenta: 'Acreedores diversos',
                lista: []
            },
            //Anticipo de clientes
            AnticipoClientes: {
                nombreCuenta: 'Anticipo de clientes',
                lista: []
            },
            //Provisiones
            Provisiones: {
                nombreCuenta: 'Provisiones',
                lista: []
            },
            //Impuestos por pagar
            ImpuestosXPagar: {
                nombreCuenta: 'Impuestos por pagar',
                lista: []
            },
            //Cuentas y documentos por pagar a largo plazo
            CuentasDocumentosXPagarLargoPlazo: {
                nombreCuenta: 'Cuentas y documentos por pagar a largo plazo',
                lista: []
            },
            //Instrumentos financieros a largo plazo
            InstrumentosFinancierosLargoPlazo: {
                nombreCuenta: 'Instrumentos financieros a largo plazo',
                lista: []
            },
            //Aportaciones para futuros aumentos de capital
            AportacionesParaFuturosAumentosCapital: {
                nombreCuenta: 'Aportaciones para futuros aumentos de capital',
                lista: []
            },
            //Impuesto a la utilidad diferido por pagar
            ImpuestoUtilidadDiferidoXPagar: {
                nombreCuenta: 'Impuesto a la utilidad diferido por pagar',
                lista: []
            },
            //Beneficio a empleados
            BeneficioEmpleados: {
                nombreCuenta: 'Beneficio a empleados',
                lista: []
            },
            //Provisiones a largo plazo
            ProvisionesLargoPlazo: {
                nombreCuenta: 'Provisiones a largo plazo',
                lista: []
            },
            //Capital social
            CapitalSocial: {
                nombreCuenta: 'Capital social',
                lista: []
            },
            //Utilidades del ejercicio
            UtilidadesEjercicio: {
                nombreCuenta: 'Utilidades del ejercicio',
                lista: []
            },
            //Otros resultados integrales
            OtrosResultadosIntegrales: {
                nombreCuenta: 'Otros resultados integrales',
                lista: []
            },

            totalEfectivoEquivalentesDeEfectivo: 0,
            totalInstrumentosFinancierosNegociación: 0,
            totalClientes: 0,
            totalCuentasDocumentosXCobrar: 0,
            totalDeudoresDiversos: 0,
            totalEstimaciónCuentasIncobrables: 0,
            totalImpuestoXRecuperar: 0,
            totalInventarios: 0,
            totalPagosAnticipados: 0,
            totalPropiedadesPlantasEquipo: 0,
            totalCuentasDocumentosXCobrarLargoPlazo: 0,
            totalAnticipoProveedores: 0,
            totalActivosIntangibles: 0,
            totalInversionesAsociadas: 0,
            totalInstrumentosFinancierosXCobrarLargoPlazo: 0,
            totalProveedores: 0,
            totalCuentasDocumentosXPagar: 0,
            totalInstrumentosFinancieros: 0,
            totalAcreedoresDiversos: 0,
            totalAnticipoClientes: 0,
            totalProvisiones: 0,
            totalImpuestosXPagar: 0,
            totalCuentasDocumentosXPagarLargoPlazo: 0,
            totalInstrumentosFinancierosLargoPlazo: 0,
            totalAportacionesParaFuturosAumentosCapital: 0,
            totalImpuestoUtilidadDiferidoXPagar: 0,
            totalBeneficioEmpleados: 0,
            totalProvisionesLargoPlazo: 0,
            totalCapitalSocial: 0,
            totalUtilidadesEjercicio: 0,
            totalOtrosResultadosIntegrales: 0,

            columns: [
                { name: 'cuenta', align: 'center', label: 'Cuenta', field: 'cuenta', sortable: true },
                { name: 'primerAnio', align: 'center', label: 'Primer año', field: 'primerAnio', sortable: true },
                { name: 'segundoAnio', align: 'center', label: 'Segundo año', field: 'segundoAnio', sortable: true },
                { name: 'diferencia', align: 'center', label: 'Diferencia', field: 'diferencia', sortable: true },
            ],

            itemBalanza: [],
            itemsEstadoDeResultados: [],

            banderaBonificacion: false,
            banderaCierre: false,

            balanza2: [],
            balanzaCompleta: [],

            // Activos disponibles para venta
            itemsActivosDisponiblesVenta: [{ cuenta: 'Activos disponibles para venta', primerAnio: 0, segundoAnio: 0, diferencia: 0 }],
            headersActivosDisponiblesVenta: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            // Impuestos a la utilidad por pagar
            itemsImpuestosUtilidadXPagar: [{ cuenta: 'Impuestos a la utilidad por pagar', primerAnio: 0, segundoAnio: 0, diferencia: 0 }],
            headersImpuestosUtilidadXPagar: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            // Rentas cobradas por anticipado
            itemsRentasCobradasXAnticipado: [{ cuenta: 'Rentas cobradas por anticipado', primerAnio: 0, segundoAnio: 0, diferencia: 0 }],
            headersRentasCobradasXAnticipado: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            // activosDisponiblesVenta: 0,
            rentasCobradasXAnticipado: 0,
            participacionControladora: 0,
            participacionNoControladora: 0,

            dialogFlujoEfectivoEstado: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        diferenciaActivosDispVent() {
            let diferencia = this.itemsActivosDisponiblesVenta[0].primerAnio - this.itemsActivosDisponiblesVenta[0].segundoAnio
            this.itemsActivosDisponiblesVenta[0].diferencia = diferencia
            return diferencia
        },
        diferenciaImpuestosUtilPagar() {
            let diferencia = this.itemsImpuestosUtilidadXPagar[0].primerAnio - this.itemsImpuestosUtilidadXPagar[0].segundoAnio
            this.itemsImpuestosUtilidadXPagar[0].diferencia = diferencia
            return diferencia
        },
        diferenciaRentasCobradasAnticipado() {
            let diferencia = this.itemsRentasCobradasXAnticipado[0].primerAnio - this.itemsRentasCobradasXAnticipado[0].segundoAnio
            this.itemsRentasCobradasXAnticipado[0].diferencia = diferencia
            return diferencia
        },
    },
    created() {
    },
    methods: {
        async GetReporte() {
            this.$store.state.listActividadesOperacion = []
            // ACTIVIDADES DE INVERSIÓN
            this.$store.state.listActividadesInversion = []
            // ACTIVIDADES DE FINANCIAMIENTO
            this.$store.state.listActividadesFinanciamiento = []
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando reporte. Espere...', messageColor: 'white' })
            await this.GetBalanza();
            await this.listasCombinadas();
            await this.clasifica();
            await this.GetEstadoDeResultados();
            this.$q.loading.hide()

        },

        async GetBalanza(tipo) {
            this.balanza2 = [];
            try {
                var fechaIniAntigua = this.selectedAnioR + '-01-01'
                var fechaFinAntigua = this.selectedAnioR + '-12-31'

                let response = await axios.get('Reportes/GetBalanzaAsync/erp_' + this.token.rfc + '/' + fechaIniAntigua + '/' + fechaFinAntigua)
                let x = response.data;
                console.log(x);
                this.balanza2 = [...x]

            } catch (error) {
                console.log(error)
            }
        },

        async GetEstadoDeResultados() {
            try {
                this.itemsEstadoDeResultados = [];
                var fechaIniRec = this.selectedAnioR + '-01-01'
                var fechaFinRec = this.selectedAnioR + '-12-31'


                let response = await axios.get('Reportes/GetEstadoResultadosAsync/erp_' + this.token.rfc + '/' + fechaIniRec + '/' + fechaFinRec + '/' + this.banderaBonificacion + '/' + this.banderaCierre);
                let respuesta = response.data;
                console.log(respuesta)

                //CREAMOS LA ESTRUCTURA DE LA TABLA
                let salto = { signo: '', descripcion: '', importe: '-' };

                //INGRESOS
                let ingresos = respuesta.filter(x => x.tipo === 'INGRESOS')
                let sumIngresos = ingresos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objIngresos = { signo: '+', descripcion: 'Ingresos netos', importe: sumIngresos }
                this.itemsEstadoDeResultados.push(objIngresos);

                // COSTOS
                let costos = respuesta.filter(x => x.tipo === 'COSTOS')
                let sumCosto = costos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objCosto = { signo: '-', descripcion: 'Costo de ventas', importe: sumCosto }
                this.itemsEstadoDeResultados.push(objCosto);

                //UTILIDAD BRUTA
                let objUtilidadBruta = { signo: '=', descripcion: 'UTILIDAD BRUTA', importe: sumIngresos - sumCosto }
                this.itemsEstadoDeResultados.push(objUtilidadBruta);
                this.itemsEstadoDeResultados.push(salto);

                //GASTOS GENERALES
                let gastos = respuesta.filter(x => x.tipo === 'GASTOS')
                let sumGastosGenerales = gastos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objGastosGenerales = { signo: '-', descripcion: 'Gastos generales', importe: sumGastosGenerales }
                this.itemsEstadoDeResultados.push(objGastosGenerales);

                //UTILIDAD DE OPERACION
                let objUtilidadDeOperacion = { signo: '=', descripcion: 'UTILIDAD DE OPERACIÓN', importe: objUtilidadBruta.importe - sumGastosGenerales }
                this.itemsEstadoDeResultados.push(objUtilidadDeOperacion);
                this.itemsEstadoDeResultados.push(salto);

                //RESULTADO INTEGRAL DE FINANCIAMIENTO
                let resultado = respuesta.filter(x => x.tipo === 'RESULTADO')
                let sumResultadoIntegral = resultado.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objResultadoIntegral = { signo: '-', descripcion: 'Resultado integral de financiamiento', importe: sumResultadoIntegral }
                this.itemsEstadoDeResultados.push(objResultadoIntegral);

                //PARTICIPACIONES
                let participaciones = respuesta.filter(x => x.tipo === 'PARTICIPACIONES')
                let sumParticipaciones = participaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objParticipaciones = { signo: '+', descripcion: 'Participación en asociadas', importe: sumParticipaciones }
                this.itemsEstadoDeResultados.push(objParticipaciones);

                //UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD
                let objUtilidadImpuestos = { signo: '=', descripcion: 'UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD', importe: objUtilidadDeOperacion.importe - sumResultadoIntegral + sumParticipaciones }
                this.itemsEstadoDeResultados.push(objUtilidadImpuestos);
                this.itemsEstadoDeResultados.push(salto);

                //IMPUESTOS A LA UTILIDAD
                let Impuestos = respuesta.filter(x => x.tipo === 'IMPUESTOS')
                let sumImpuestos = Impuestos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objImpuestos = { signo: '-', descripcion: 'Impuestos a la utilidad', importe: sumImpuestos }
                this.itemsEstadoDeResultados.push(objImpuestos);

                //UTILIDAD DE OPERACIONES CONTINUAS
                let objUtilidadOperaciones = { signo: '=', descripcion: 'UTILIDAD DE OPERACIONES CONTINUAS', importe: objUtilidadImpuestos.importe - sumImpuestos }
                this.itemsEstadoDeResultados.push(objUtilidadOperaciones);
                this.itemsEstadoDeResultados.push(salto);

                //OPERACIONES DISCONTINUADAS
                let operaciones = respuesta.filter(x => x.tipo === 'OPERACIONES')
                let sumDiscontinuadas = operaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objDiscontinuadas = { signo: '-', descripcion: 'Operaciones discontinuadas', importe: sumDiscontinuadas }
                this.itemsEstadoDeResultados.push(objDiscontinuadas);

                //UTILIDAD DE OPERACIONES CONTINUAS
                let desc_ = 'UTILIDAD NETA'
                if (objUtilidadOperaciones.importe - sumDiscontinuadas < 0) {
                    desc_ = 'PÉRDIDA NETA'
                }
                let objUtilidadNeta = { signo: '=', descripcion: desc_, importe: objUtilidadOperaciones.importe - sumDiscontinuadas }
                console.log(objUtilidadNeta)
                this.itemsEstadoDeResultados.push(objUtilidadNeta);
                this.$store.state.estadoResultado = objUtilidadNeta.importe;


            } catch (error) {
                console.log(error)
            }
        },
        listasCombinadas() {
            this.balanzaCompleta = [];

            for (let i = 0; i < this.balanza2.length; i++) {
                var cuenta = this.balanza2[i].nombreCuenta;
                var primerAnio = 0;
                var segundoAnio = 0;
                var numeroCuenta = this.balanza2[i].noCuenta;
                var naturaleza = this.balanza2[i].naturaleza;
                if (this.balanza2[i].noCuenta == '213') {
                    console.log('213', this.balanza2[i])
                }
                if (this.balanza2[i].noCuenta == '216') {
                    console.log('216', this.balanza2[i])
                }
                if (naturaleza == 'D') {
                    primerAnio = this.balanza2[i].debeInicial;
                    segundoAnio = this.balanza2[i].debeFinal;
                } else {
                    primerAnio = this.balanza2[i].haberInicial;
                    segundoAnio = this.balanza2[i].haberFinal;
                }

                var diferencia = segundoAnio - primerAnio;

                this.balanzaCompleta.push({
                    cuenta,
                    primerAnio,
                    segundoAnio,
                    diferencia,
                    numeroCuenta
                });
            }
        },
        listasCombinadas2() {
            this.balanzaCompleta = [];

            for (let i = 0; i < this.balanza2.length; i++) {
                var cuenta = this.balanza2[i].nombreCuenta;
                var primerAnio = 0;
                var segundoAnio = 0;

                switch (this.balanza2[i].noCuenta) {
                    case ('170'):
                    case ('171'):
                    case ('172'):
                    case ('201'):
                    case ('202'):
                    case ('208'):
                    case ('209'):
                    case ('203'):
                    case ('204'):
                    case ('205'):
                    case ('206'):
                    case ('210'):
                    case ('211'):
                    case ('212'):
                    case ('213'):
                    case ('214'):
                    case ('215'):
                    case ('216'):
                    case ('217'):
                    case ('218'):
                    case ('251'):
                    case ('252'):
                    case ('253'):
                    case ('254'):
                    case ('301'):
                    case ('259'):
                    case ('255'):
                    case ('256'):
                    case ('257'):
                    case ('258'):
                    case ('301'):
                    case ('302'):
                    case ('303'):
                    case ('304'):
                    case ('305'):
                    case ('306'):

                        if (this.balanza2[i].naturaleza == 'D') {
                            primerAnio = this.balanza2[i].debeInicial;
                            segundoAnio = this.balanza2[i].debeFinal;
                        } else {
                            primerAnio = this.balanza2[i].haberInicial;
                            segundoAnio = this.balanza2[i].haberFinal;
                        }

                        break;
                    default:
                        if (this.balanza2[i].naturaleza == 'D') {
                            primerAnio = this.balanza2[i].debeInicial;
                            segundoAnio = this.balanza2[i].debeFinal;
                        } else {
                            primerAnio = this.balanza2[i].haberInicial;
                            segundoAnio = this.balanza2[i].haberFinal;
                        }
                        break;
                }

                var diferencia = segundoAnio - primerAnio;
                var numeroCuenta = this.balanza2[i].noCuenta;

                this.balanzaCompleta.push({
                    cuenta,
                    primerAnio,
                    segundoAnio,
                    diferencia,
                    numeroCuenta
                });

            }

        },

        clasifica() {
            // LIMPIA LOS DATOS
            this.$store.state.listActividadesOperacion = [];
            this.limpiaTablas();
            for (let a of this.balanzaCompleta) {

                switch (a.numeroCuenta) {
                    // Efectivo y equivalentes de efectivo
                    case ('101'):
                    case ('102'):
                    case ('103'):
                        this.EfectivoEquivalentesDeEfectivo.lista.push(a)
                        break;
                    //Instrumentos financieros de negociación
                    case ('104'):
                        this.InstrumentosFinancierosNegociación.lista.push(a)
                        break;
                    // Clientes
                    case ('105'):
                        this.Clientes.lista.push(a)
                        break;
                    // Cuentas y documentos por cobrar
                    case ('106'):
                    case ('118'):
                    case ('119'):
                        this.CuentasDocumentosXCobrar.lista.push(a)
                        break;
                    // Deudores diversos
                    case ('107'):
                        this.DeudoresDiversos.lista.push(a)
                        break;
                    // Estimación de cuentas incobrables
                    case ('108'):
                        this.EstimaciónCuentasIncobrables.lista.push(a)
                        break;
                    // Impuesto por recuperar
                    case ('110'):
                    case ('113'):
                        this.ImpuestoXRecuperar.lista.push(a)
                        break;
                    // Inventarios
                    case ('115'):
                        this.Inventarios.lista.push(a)
                        break;
                    // Pagos anticipados
                    case ('109'):
                    case ('111'):
                    case ('112'):
                        this.PagosAnticipados.lista.push(a)
                        break;
                    // Propiedades, plantas y equipo
                    case ('151'):
                    case ('152'):
                    case ('153'):
                    case ('154'):
                    case ('155'):
                    case ('156'):
                    case ('157'):
                    case ('158'):
                    case ('159'):
                    case ('160'):
                    case ('161'):
                    case ('162'):
                    case ('163'):
                    case ('164'):
                    case ('165'):
                    case ('166'):
                    case ('167'):
                    case ('168'):
                    case ('169'):
                    case ('170'):
                    case ('171'):
                    case ('172'):
                        this.PropiedadesPlantasEquipo.lista.push(a)
                        break;
                    // Cuentas y documentos por cobrar a largo plazo
                    case ('186'):
                        this.CuentasDocumentosXCobrarLargoPlazo.lista.push(a)
                        break;
                    // Anticipo a proveedores
                    case ('120'):
                        this.AnticipoProveedores.lista.push(a)
                        break;
                    // Activos intangibles
                    case ('173'):
                    case ('174'):
                    case ('175'):
                    case ('176'):
                    case ('177'):
                    case ('178'):
                    case ('179'):
                    case ('180'):
                    case ('181'):
                    case ('182'):
                    case ('183'):
                    case ('184'):
                    case ('185'):
                        this.ActivosIntangibles.lista.push(a)
                        break;
                    // Inversiones en asociadas
                    case ('188'):
                    case ('189'):
                        this.InversionesAsociadas.lista.push(a)
                        break;
                    // Instrumentos financieros por cobrar a largo plazo
                    case ('190'):
                    case ('191'):
                        this.InstrumentosFinancierosXCobrarLargoPlazo.lista.push(a)
                        break;
                    // Proveedores
                    case ('201'):
                        this.Proveedores.lista.push(a)
                        break;
                    // Cuentas y documentos por pagar
                    case ('202'):
                    case ('203'):
                    case ('208'):
                    case ('209'):
                        this.CuentasDocumentosXPagar.lista.push(a)
                        break;
                    // Instrumentos financieros
                    case ('204'):
                        this.InstrumentosFinancieros.lista.push(a)
                        break;
                    // Acreedores diversos
                    case ('205'):
                        this.AcreedoresDiversos.lista.push(a)
                        break;
                    // Anticipo de clientes
                    case ('206'):
                        this.AnticipoClientes.lista.push(a)
                        break;
                    // Provisiones
                    case ('210'):
                    case ('211'):
                    case ('212'):
                        this.Provisiones.lista.push(a)
                        break;
                    // Impuestos por pagar
                    case ('213'):
                    case ('214'):
                    case ('215'):
                    case ('216'):
                    case ('217'):
                        this.ImpuestosXPagar.lista.push(a)
                        break;
                    // Cuentas y documentos por pagar a largo plazo
                    case ('218'):
                    case ('251'):
                    case ('252'):
                    case ('253'):
                        this.CuentasDocumentosXPagarLargoPlazo.lista.push(a)
                        break;
                    // Instrumentos financieros a largo plazo
                    case ('254'):
                        this.InstrumentosFinancierosLargoPlazo.lista.push(a)
                        break;
                    // Aportaciones para futuros aumentos de capital
                    case ('301.03'):
                        this.AportacionesParaFuturosAumentosCapital.lista.push(a)
                        break;
                    // Impuesto a la utilidad diferido por pagar
                    case ('259'):
                        this.ImpuestoUtilidadDiferidoXPagar.lista.push(a)
                        break;
                    // Beneficio a empleados
                    case ('255'):
                        this.BeneficioEmpleados.lista.push(a)
                        break;
                    // Provisiones a largo plazo
                    case ('256'):
                    case ('257'):
                    case ('258'):
                        this.ProvisionesLargoPlazo.lista.push(a)
                        break;
                    // Capital social
                    case ('301.01'):
                        // case ('302'):
                        // case ('303'):
                        this.CapitalSocial.lista.push(a)
                        break;
                    // Utilidades del ejercicio
                    case ('304'):
                    case ('305'):
                        this.UtilidadesEjercicio.lista.push(a)
                        break;
                    // Otros resultados integrales
                    case ('306'):
                        this.OtrosResultadosIntegrales.lista.push(a)
                        break;
                    // default:
                    //     this.cuenta1.lista.push('Sin categoria');
                    //     break;

                }
            }
            this.sumatorioas()

            var listaActividades = [];
            //Clientes
            var cuenta = this.Clientes.nombreCuenta;
            if (this.totalClientes === 0) {
                var total = 0
            } else {
                var total = (this.totalClientes) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Cuentas y documentos por cobrar
            cuenta = this.CuentasDocumentosXCobrar.nombreCuenta;
            if (this.totalCuentasDocumentosXCobrar === 0) {
                total = 0
            } else {
                total = (this.totalCuentasDocumentosXCobrar) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Deudores diversos
            cuenta = this.DeudoresDiversos.nombreCuenta;
            console.log('2', this.totalDeudoresDiversos)
            if (this.totalDeudoresDiversos === 0) {
                total = 0
            } else {
                total = (this.totalDeudoresDiversos) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            // Estimación de cuentas incobrables
            cuenta = this.EstimaciónCuentasIncobrables.nombreCuenta;
            if (this.totalEstimaciónCuentasIncobrables === 0) {
                total = 0
            } else {
                total = (this.totalEstimaciónCuentasIncobrables) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Impuesto por recuperar
            cuenta = this.ImpuestoXRecuperar.nombreCuenta;
            if (this.totalImpuestoXRecuperar === 0) {
                total = 0
            } else {
                total = (this.totalImpuestoXRecuperar) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Inventarios
            cuenta = this.Inventarios.nombreCuenta;
            if (this.totalInventarios === 0) {
                total = 0
            } else {
                total = (this.totalInventarios) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Pagos anticipados
            cuenta = this.PagosAnticipados.nombreCuenta;
            if (this.totalPagosAnticipados === 0) {
                total = 0
            } else {
                total = (this.totalPagosAnticipados) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });

            var indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Activos disponibles para venta');
            if (indexToRemove !== -1) {

                this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
            }
            cuenta = 'Activos disponibles para venta'
            total = (this.itemsActivosDisponiblesVenta[0].diferencia) * -1
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Cuentas y documentos por cobrar a largo plazo
            cuenta = this.CuentasDocumentosXCobrarLargoPlazo.nombreCuenta;
            if (this.totalCuentasDocumentosXCobrarLargoPlazo === undefined) {
                total = 0
            } else {
                total = (this.totalCuentasDocumentosXCobrarLargoPlazo) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Anticipo a proveedores
            cuenta = this.AnticipoProveedores.nombreCuenta;
            if (this.totalAnticipoProveedores === undefined) {
                total = 0
            } else {
                total = (this.totalAnticipoProveedores) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Activos intangibles
            cuenta = this.ActivosIntangibles.nombreCuenta;
            if (this.totalActivosIntangibles === 0) {
                total = 0
            } else {
                total = (this.totalActivosIntangibles) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Inversiones en asociadas
            cuenta = this.InversionesAsociadas.nombreCuenta;
            if (this.totalInversionesAsociadas === 0) {
                total = 0
            } else {
                total = (this.totalInversionesAsociadas) * -1;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Proveedores
            cuenta = this.Proveedores.nombreCuenta;
            if (this.totalProveedores === 0) {
                total = 0
            } else {
                total = this.totalProveedores;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            // Cuentas y documentos por pagar
            cuenta = this.CuentasDocumentosXPagar.nombreCuenta;
            if (this.totalCuentasDocumentosXPagar === 0) {
                total = 0
            } else {
                total = this.totalCuentasDocumentosXPagar;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Instrumentos financieros de negociación
            cuenta = this.InstrumentosFinancierosNegociación.nombreCuenta;
            if (this.totalInstrumentosFinancierosNegociación === 0) {
                total = 0
            } else {
                total = this.totalInstrumentosFinancierosNegociación;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            // Acreedores diversos
            cuenta = this.AcreedoresDiversos.nombreCuenta;
            if (this.totalAcreedoresDiversos === 0) {
                total = 0
            } else {
                total = this.totalAcreedoresDiversos;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Impuestos a la utilidad por pagar
            //BUSCAMOS SI ES QUE YA EXISTE UN REGISTO Y DE SER ASI LO ELIMINAMOS PARA AGREGARLO NUEVAMENTE,
            //ESTO EN EL CASO DE QUE REALICEN PRIMERO LA BUSQUEDA Y DESPUES LA INSERCION A MANO O VICEVERSA
            indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Impuestos a la utilidad por pagar');
            if (indexToRemove !== -1) {
                // Eliminar el elemento usando splice()
                this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
            }
            cuenta = 'Impuestos a la utilidad por pagar'
            total = this.itemsImpuestosUtilidadXPagar[0].diferencia
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            // Anticipo de clientes
            cuenta = this.AnticipoClientes.nombreCuenta;
            if (this.totalAnticipoClientes === 0) {
                total = 0
            } else {
                total = this.totalAnticipoClientes;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Impuestos por pagar
            cuenta = this.ImpuestosXPagar.nombreCuenta;
            if (this.totalImpuestosXPagar === 0) {
                total = 0
            } else {
                total = this.totalImpuestosXPagar;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Cuentas y documentos por pagar a largo plazo
            cuenta = this.CuentasDocumentosXPagarLargoPlazo.nombreCuenta;
            if (this.totalCuentasDocumentosXPagarLargoPlazo === 0) {
                total = 0
            } else {
                total = this.totalCuentasDocumentosXPagarLargoPlazo;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Instrumentos financieros a largo plazo
            cuenta = this.InstrumentosFinancierosLargoPlazo.nombreCuenta;
            if (this.totalInstrumentosFinancierosLargoPlazo === 0) {
                total = 0
            } else {
                total = this.totalInstrumentosFinancierosLargoPlazo;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Rentas cobradas por anticipado
            //BUSCAMOS SI ES QUE YA EXISTE UN REGISTO Y DE SER ASI LO ELIMINAMOS PARA AGREGARLO NUEVAMENTE,
            //ESTO EN EL CASO DE QUE REALICEN PRIMERO LA BUSQUEDA Y DESPUES LA INSERCION A MANO O VICEVERSA
            indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Rentas cobradas por anticipado');
            if (indexToRemove !== -1) {
                // Eliminar el elemento usando splice()
                this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
            }
            cuenta = 'Rentas cobradas por anticipado'
            total = this.itemsRentasCobradasXAnticipado[0].diferencia
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Aportaciones para futuros aumentos de capital
            cuenta = this.AportacionesParaFuturosAumentosCapital.nombreCuenta;
            if (this.totalAportacionesParaFuturosAumentosCapital === 0) {
                total = 0
            } else {
                total = this.totalAportacionesParaFuturosAumentosCapital;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Impuesto a la utilidad diferido por pagar
            cuenta = this.ImpuestoUtilidadDiferidoXPagar.nombreCuenta;
            if (this.totalImpuestoUtilidadDiferidoXPagar === 0) {
                total = 0
            } else {
                total = this.totalImpuestoUtilidadDiferidoXPagar;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });
            //Beneficio a empleados
            cuenta = this.BeneficioEmpleados.nombreCuenta;
            if (this.totalBeneficioEmpleados === 0) {
                total = 0
            } else {
                total = this.totalBeneficioEmpleados;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });

            //Provisiones a largo plazo
            cuenta = this.Provisiones.nombreCuenta;
            if (this.totalProvisiones === 0) {
                total = 0
            } else {
                total = this.totalProvisiones;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });

            //Provisiones a largo plazo
            cuenta = this.ProvisionesLargoPlazo.nombreCuenta;
            if (this.totalProvisionesLargoPlazo === 0) {
                total = 0
            } else {
                total = this.totalProvisionesLargoPlazo;
            }
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });

            //ACTIVIDADES DE INVERSION
            // Propiedades, plantas y equipo
            cuenta = this.PropiedadesPlantasEquipo.nombreCuenta;

            for (let ncuenta of this.PropiedadesPlantasEquipo.lista) {

                if (ncuenta.numeroCuenta == '171') {
                    ncuenta.primerAnio = ncuenta.primerAnio * -1
                    ncuenta.segundoAnio = ncuenta.segundoAnio * -1
                }
            }

            // console.log('ppe', this.PropiedadesPlantasEquipo.lista[2].numeroCuenta)

            if (this.totalPropiedadesPlantasEquipo === 0) {
                total = 0
            } else {
                total = (this.totalPropiedadesPlantasEquipo) * -1;
            }
            this.$store.state.listActividadesInversion.push({
                cuenta,
                total,
            });

            // ACTIVIDADES DE FINANCIAMIENTO
            //Capital social
            cuenta = this.CapitalSocial.nombreCuenta;
            if (this.totalCapitalSocial === 0) {
                total = 0
            } else {
                total = this.totalCapitalSocial;
            }
            this.$store.state.listActividadesFinanciamiento.push({
                cuenta,
                total,
            });
            //Utilidades del ejercicio
            cuenta = this.UtilidadesEjercicio.nombreCuenta;
            if (this.totalUtilidadesEjercicio === 0) {
                total = 0
            } else {
                total = this.totalUtilidadesEjercicio;
            }
            this.$store.state.listActividadesFinanciamiento.push({
                cuenta,
                total,
            });
            //Otros resultados integrales
            cuenta = this.OtrosResultadosIntegrales.nombreCuenta;
            if (this.totalOtrosResultadosIntegrales === 0) {
                total = 0
            } else {
                total = this.totalOtrosResultadosIntegrales;
            }
            this.$store.state.listActividadesFinanciamiento.push({
                cuenta,
                total,
            });

            //EFECTIVO AL INICIO DEL EJERCICIO
            let sumaEfectivoEquivalentesDeEfectivo2 = this.sum(this.EfectivoEquivalentesDeEfectivo.lista, 'primerAnio');
            console.log(sumaEfectivoEquivalentesDeEfectivo2)

            if (sumaEfectivoEquivalentesDeEfectivo2 === 0) {
                this.$store.state.efectivoInicioEjercicioStore = 0
            } else {
                this.$store.state.efectivoInicioEjercicioStore = sumaEfectivoEquivalentesDeEfectivo2;
            }

            // console.log(this.$store.state.listActividadesOperacion)

        },
        sumatorioas() {
            //Efectivo y equivalentes de efectivo
            this.totalEfectivoEquivalentesDeEfectivo = this.sum(this.EfectivoEquivalentesDeEfectivo.lista, 'diferencia');

            //Instrumentos financieros de negociación
            this.totalInstrumentosFinancierosNegociación = this.sum(this.InstrumentosFinancierosNegociación.lista, 'diferencia');

            //Clientes
            this.totalClientes = this.sum(this.Clientes.lista, 'diferencia');

            //Cuentas y documentos por cobrar
            this.totalCuentasDocumentosXCobrar = this.sum(this.CuentasDocumentosXCobrar.lista, 'diferencia');

            //Deudores diversos
            console.log('1', this.DeudoresDiversos.lista)
            this.totalDeudoresDiversos = this.sum(this.DeudoresDiversos.lista, 'diferencia');

            //Estimación de cuentas incobrables
            this.totalEstimaciónCuentasIncobrables = this.sum(this.EstimaciónCuentasIncobrables.lista, 'diferencia');

            //Impuesto por recuperar
            this.totalImpuestoXRecuperar = this.sum(this.ImpuestoXRecuperar.lista, 'diferencia');

            //Inventarios
            this.totalInventarios = this.sum(this.Inventarios.lista, 'diferencia');

            //Pagos anticipados
            this.totalPagosAnticipados = this.sum(this.PagosAnticipados.lista, 'diferencia');

            //Propiedades, plantas y equipo
            this.totalPropiedadesPlantasEquipo = this.sum(this.PropiedadesPlantasEquipo.lista, 'diferencia');

            //Cuentas y documentos por cobrar a largo plazo
            this.totalCuentasDocumentosXCobrarLargoPlazo = this.sum(this.CuentasDocumentosXCobrarLargoPlazo.lista, 'diferencia');

            //Anticipo a proveedores
            this.totalAnticipoProveedores = this.sum(this.AnticipoProveedores.lista, 'diferencia');

            //Activos intangibles
            this.totalActivosIntangibles = this.sum(this.ActivosIntangibles.lista, 'diferencia');

            //Inversiones en asociadas
            this.totalInversionesAsociadas = this.sum(this.InversionesAsociadas.lista, 'diferencia');

            //Instrumentos financieros por cobrar a largo plazo
            this.totalInstrumentosFinancierosXCobrarLargoPlazo = this.sum(this.InstrumentosFinancierosXCobrarLargoPlazo.lista, 'diferencia');

            //Proveedores
            this.totalProveedores = this.sum(this.Proveedores.lista, 'diferencia');

            // Cuentas y documentos por pagar
            this.totalCuentasDocumentosXPagar = this.sum(this.CuentasDocumentosXPagar.lista, 'diferencia');

            //Instrumentos financieros a largo plazo
            this.totalInstrumentosFinancieros = this.sum(this.InstrumentosFinancieros.lista, 'diferencia');

            // Acreedores diversos
            this.totalAcreedoresDiversos = this.sum(this.AcreedoresDiversos.lista, 'diferencia');

            //Anticipo de clientes
            this.totalAnticipoClientes = this.sum(this.AnticipoClientes.lista, 'diferencia');

            //Provisiones
            this.totalProvisiones = this.sum(this.Provisiones.lista, 'diferencia');

            //Impuestos por pagar
            this.totalImpuestosXPagar = this.sum(this.ImpuestosXPagar.lista, 'diferencia');

            //Cuentas y documentos por pagar a largo plazo
            this.totalCuentasDocumentosXPagarLargoPlazo = this.sum(this.CuentasDocumentosXPagarLargoPlazo.lista, 'diferencia');

            //Instrumentos financieros a largo plazo
            this.totalInstrumentosFinancierosLargoPlazo = this.sum(this.InstrumentosFinancierosLargoPlazo.lista, 'diferencia');

            //Aportaciones para futuros aumentos de capital
            this.totalAportacionesParaFuturosAumentosCapital = this.sum(this.AportacionesParaFuturosAumentosCapital.lista, 'diferencia');

            //Impuesto a la utilidad diferido por pagar
            this.totalImpuestoUtilidadDiferidoXPagar = this.sum(this.ImpuestoUtilidadDiferidoXPagar.lista, 'diferencia');

            //Beneficio a empleados
            this.totalBeneficioEmpleados = this.sum(this.BeneficioEmpleados.lista, 'diferencia');

            //Provisiones a largo plazo
            this.totalProvisionesLargoPlazo = this.sum(this.ProvisionesLargoPlazo.lista, 'diferencia');

            //Capital social
            this.totalCapitalSocial = this.sum(this.CapitalSocial.lista, 'diferencia');

            //Utilidades del ejercicio
            this.totalUtilidadesEjercicio = this.sum(this.UtilidadesEjercicio.lista, 'diferencia');

            //Otros resultados integrales
            this.totalOtrosResultadosIntegrales = this.sum(this.OtrosResultadosIntegrales.lista, 'diferencia');

        },
        limpiaTablas() {
            //Efectivo y equivalentes de efectivo
            this.EfectivoEquivalentesDeEfectivo = {
                nombreCuenta: 'Efectivo y equivalentes de efectivo',
                lista: []
            };
            //Instrumentos financieros de negociación
            this.InstrumentosFinancierosNegociación = {
                nombreCuenta: 'Instrumentos financieros de negociación',
                lista: []
            };
            //Clientes
            this.Clientes = {
                nombreCuenta: 'Clientes',
                lista: []
            };
            //Cuentas y documentos por cobrar
            this.CuentasDocumentosXCobrar = {
                nombreCuenta: 'Cuentas y documentos por cobrar',
                lista: []
            };
            //Deudores diversos
            this.DeudoresDiversos = {
                nombreCuenta: 'Deudores diversos',
                lista: []
            };
            //Estimación de cuentas incobrables
            this.EstimaciónCuentasIncobrables = {
                nombreCuenta: 'Estimación de cuentas incobrables',
                lista: []
            };
            //Impuesto por recuperar
            this.ImpuestoXRecuperar = {
                nombreCuenta: 'Impuesto por recuperar',
                lista: []
            };
            //Inventarios
            this.Inventarios = {
                nombreCuenta: 'Inventarios',
                lista: []
            };
            //Pagos anticipados
            this.PagosAnticipados = {
                nombreCuenta: 'Pagos anticipados',
                lista: []
            };
            //Propiedades, plantas y equipo
            this.PropiedadesPlantasEquipo = {
                nombreCuenta: 'Propiedades, plantas y equipo',
                lista: []
            };
            //Cuentas y documentos por cobrar a largo plazo
            this.CuentasDocumentosXCobrarLargoPlazo = {
                nombreCuenta: 'Cuentas y documentos por cobrar a largo plazo',
                lista: []
            };
            //Anticipo a proveedores
            this.AnticipoProveedores = {
                nombreCuenta: 'Anticipo a proveedores',
                lista: []
            };
            //Activos intangibles
            this.ActivosIntangibles = {
                nombreCuenta: 'Activos intangibles',
                lista: []
            };
            //Inversiones en asociadas
            this.InversionesAsociadas = {
                nombreCuenta: 'Inversiones en asociadas',
                lista: []
            };
            //Instrumentos financieros por cobrar a largo plazo
            this.InstrumentosFinancierosXCobrarLargoPlazo = {
                nombreCuenta: 'Instrumentos financieros por cobrar a largo plazo',
                lista: []
            };
            //Proveedores
            this.Proveedores = {
                nombreCuenta: 'Proveedores',
                lista: []
            };
            //Cuentas y documentos por pagar
            this.CuentasDocumentosXPagar = {
                nombreCuenta: 'Cuentas y documentos por pagar',
                lista: []
            };
            //Instrumentos financieros
            this.InstrumentosFinancieros = {
                nombreCuenta: 'Instrumentos financieros',
                lista: []
            };
            //Acreedores diversos
            this.AcreedoresDiversos = {
                nombreCuenta: 'Acreedores diversos',
                lista: []
            };
            //Anticipo de clientes
            this.AnticipoClientes = {
                nombreCuenta: 'Anticipo de clientes',
                lista: []
            };
            //Provisiones
            this.Provisiones = {
                nombreCuenta: 'Provisiones',
                lista: []
            };
            //Impuestos por pagar
            this.ImpuestosXPagar = {
                nombreCuenta: 'Impuestos por pagar',
                lista: []
            };
            //Cuentas y documentos por pagar a largo plazo
            this.CuentasDocumentosXPagarLargoPlazo = {
                nombreCuenta: 'Cuentas y documentos por pagar a largo plazo',
                lista: []
            };
            //Instrumentos financieros a largo plazo
            this.InstrumentosFinancierosLargoPlazo = {
                nombreCuenta: 'Instrumentos financieros a largo plazo',
                lista: []
            };
            //Aportaciones para futuros aumentos de capital
            this.AportacionesParaFuturosAumentosCapital = {
                nombreCuenta: 'Aportaciones para futuros aumentos de capital',
                lista: []
            };
            //Impuesto a la utilidad diferido por pagar
            this.ImpuestoUtilidadDiferidoXPagar = {
                nombreCuenta: 'Impuesto a la utilidad diferido por pagar',
                lista: []
            };
            //Beneficio a empleados
            this.BeneficioEmpleados = {
                nombreCuenta: 'Beneficio a empleados',
                lista: []
            };
            //Provisiones a largo plazo
            this.ProvisionesLargoPlazo = {
                nombreCuenta: 'Provisiones a largo plazo',
                lista: []
            };
            //Capital social
            this.CapitalSocial = {
                nombreCuenta: 'Capital social',
                lista: []
            };
            //Utilidades del ejercicio
            this.UtilidadesEjercicio = {
                nombreCuenta: 'Utilidades del ejercicio',
                lista: []
            };
            //Otros resultados integrales
            this.OtrosResultadosIntegrales = {
                nombreCuenta: 'Otros resultados integrales',
                lista: []
            };
        },
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },
        GetDialogFlujo() {
            this.$store.state.anioreciente = this.selectedAnioR
            this.dialogFlujoEfectivoEstado = true
        }
    }
}
</script>

<style></style>