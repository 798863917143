import { render, staticRenderFns } from "./EstadoSituacionFinancieraAnual.vue?vue&type=template&id=a76be0a8&"
import script from "./EstadoSituacionFinancieraAnual.vue?vue&type=script&lang=js&"
export * from "./EstadoSituacionFinancieraAnual.vue?vue&type=script&lang=js&"
import style0 from "./EstadoSituacionFinancieraAnual.vue?vue&type=style&index=0&id=a76be0a8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QSelect,QIcon,QBtn,QTooltip,QTable,QTr,QTd});
